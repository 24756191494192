import { ChangeEvent, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Badge, Box, Button, Typography } from '@mui/material';
import pick from 'lodash/pick';
import { z } from 'zod';
import {
  useCopyProof,
  useDeleteProof,
  useGetMatchingProofs,
  useUpdateProof,
  useUploadProof,
} from '@/api/designs';
import { ButtonAction, FieldFactory } from '@/classes';
import TextButton from '@/components/Text/TextButton';
import { useShowLoading } from '@/contexts/AppContext';
import { DesignLayoutWithProofs, Proof, ProofType } from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';
import ExistingProofDrawer from './ExistingProofDrawer';
import ProofCard from './ProofCard';
import ProofItemDialog from './ProofItemDialog';

export default function DesignProofs({
  layout,
  type,
  canEdit,
}: {
  layout: DesignLayoutWithProofs;
  type: ProofType;
  canEdit: boolean;
}) {
  const proofs = layout[`${type}_proofs`];
  const [showProofItems, setShowProofItems] = useState(false);
  const [choosing, setChoosing] = useState(false);
  const { confirm, prompt } = useDialogs();
  const showLoading = useShowLoading();

  const { data: matches = [] } = useGetMatchingProofs(layout.id, canEdit);
  const uploadProofRequest = useUploadProof(layout, type);
  const deleteProofRequest = useDeleteProof(layout, type);
  const copyProofRequest = useCopyProof(layout, type);
  const updateProofRequest = useUpdateProof(layout, type);

  const onDelete = (proof: Proof) => {
    confirm({
      title: 'Delete Proof',
      description: 'Are you sure you want to delete this proof?',
      color: 'error',
    }).then(() => {
      deleteProofRequest.mutate(proof);
    });
  };

  const onRename = (proof: Proof) => {
    prompt({
      title: 'Rename Proof',
      fields: [FieldFactory.text('file_name').withSize('medium')],
      schema: z.object({
        file_name: z.string().min(1),
      }),
      initialValues: pick(proof, 'file_name'),
      onSubmit: (v) => updateProofRequest.mutateAsync({ ...proof, ...v }),
    });
  };

  const uploadProof = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) {
      return;
    }
    [...files].forEach((file) => {
      uploadProofRequest.mutate(file);
    });
  };

  const onChoose = (proof: Proof) => {
    setChoosing(false);
    showLoading(copyProofRequest.mutateAsync(proof));
  };

  return (
    <div>
      {proofs.length > 0 ? (
        <div>
          {proofs.map((proof) => (
            <Box display="inline-block" width={225} key={proof.id} mr={2}>
              <ProofCard
                proof={proof}
                actions={
                  canEdit
                    ? [
                        new ButtonAction('Rename', () => onRename(proof)).withIcon(Edit),
                        new ButtonAction('Delete', () => onDelete(proof)).withIcon(Delete),
                      ]
                    : []
                }
              />
            </Box>
          ))}
        </div>
      ) : (
        <Typography variant="body2" color="textSecondary">
          There are no {type} proofs for this layout.
        </Typography>
      )}

      {canEdit && (
        <Box mt={2} display="flex" alignItems="center">
          <LoadingButton
            size="small"
            variant="contained"
            sx={{ mr: 2 }}
            component="label"
            loading={uploadProofRequest.isLoading}
          >
            {uploadProofRequest.isLoading ? 'Uploading...' : 'Upload'}
            <input
              type="file"
              accept="application/pdf"
              multiple
              onChange={uploadProof}
              style={{ display: 'none' }}
            />
          </LoadingButton>

          {matches.length > 0 && (
            <Badge badgeContent={matches.length} color="secondary" sx={{ mr: 2 }}>
              <Button size="small" onClick={() => setChoosing(true)}>
                Existing Proofs
              </Button>
            </Badge>
          )}

          <Box ml="auto">
            <TextButton onClick={() => setShowProofItems(true)}>
              What item to proof this layout on?
            </TextButton>
          </Box>
        </Box>
      )}

      <ProofItemDialog
        open={showProofItems}
        onClose={() => setShowProofItems(false)}
        layoutId={layout.id}
        orderId={layout.order_id}
      />

      <ExistingProofDrawer
        open={choosing}
        onClose={() => setChoosing(false)}
        onChoose={type === 'production' ? onChoose : undefined}
        proofs={matches}
      />
    </div>
  );
}
