import { Box, Card } from '@mui/material';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import { FieldProps } from '@/classes/types';
import { Order, PurchaseOrder, PurchaseOrderItem } from '@/types';
import { useRecord } from '@/utils/genericResource';
import wrap from '@/utils/wrap';
import NoteViewer from '../Notes/NoteViewer';
import ReceivePurchaseOrder from './ReceivePurchaseOrder';

export default function ReceivePurchaseOrderField({ field }: FieldProps) {
  const items = wrap(field.value) as PurchaseOrderItem[];

  const values = useRecord<Order | PurchaseOrder>();
  const id = values.id;
  const customerId = 'customer_id' in values ? values.customer_id : undefined;
  const orderIds = uniq(
    items.map((i) => i.order_id).filter((i) => typeof i === 'number'),
  ) as number[];
  const purchaseOrderIds = uniq(items.map((i) => i.purchase_order_id)) as number[];
  const type = customerId ? 'order' : 'purchase_order';

  return (
    <>
      {orderIds.map((orderId) => (
        <NoteViewer key={orderId} tag="receiving" resource="orders" resourceId={orderId} />
      ))}
      {purchaseOrderIds.map((poId) => (
        <NoteViewer key={poId} tag="receiving" resource="purchase-orders" resourceId={poId} />
      ))}
      <Card sx={{ mb: 2 }}>
        <Box mb={2}>
          <ReceivePurchaseOrder
            title="To Receive"
            items={reject(items, 'is_received')}
            toReceive
            type={type}
            id={id}
          />
        </Box>
        <ReceivePurchaseOrder title="Received" items={items.filter((i) => i.is_received)} />
      </Card>
    </>
  );
}
