import { ChangeEvent, useState } from 'react';
import { Delete, SwapHoriz, ViewList } from '@mui/icons-material';
import {
  Box,
  CardContent,
  Checkbox,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import pick from 'lodash/pick';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import ChooseLocationForVariant from '@/components/Inventory/ChooseLocationForVariant';
import InventoryLevels from '@/components/Inventory/InventoryLevels';
import InventoryLocationLabel from '@/components/Inventory/InventoryLocationLabel';
import PickItemAvailablility from '@/components/Inventory/PickItemAvailability';
import SkuLabel from '@/components/Products/SkuLabel';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import TextButton from '@/components/Text/TextButton';
import { InventoryPick, InventoryPickItem } from '@/types';
import { genericModelReferenceSchema } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';

const PickItemRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'picked',
})<{ picked?: boolean }>(({ picked, theme }) => {
  if (picked) {
    return {
      '& .MuiTableCell-root': {
        color: theme.palette.text.secondary,
        textDecoration: 'line-through',
      },
    };
  }
  return {};
});

export default function InventoryPickItems({
  status,
  showItemStatus = true,
  items,
}: {
  status: InventoryPick['status'];
  showItemStatus?: boolean;
  items: InventoryPickItem[];
}) {
  const { prompt, confirm } = useDialogs();
  const onReload = useOnReloadRecord();
  const [viewing, setViewing] = useState<InventoryPickItem>();

  const onUpdateQty = (item: InventoryPickItem) => {
    prompt({
      title: 'Update Pick Item Qty',
      fields: [FieldFactory.number('qty').withSize('medium')],
      schema: z.object({
        qty: z.coerce.number().int().min(1),
      }),
      initialValues: pick(item, 'qty'),
      onSubmit: (v) => axios.put(`/api/inventory-pick-items/${item.id}`, v),
    }).then(() => {
      onReload();
    });
  };

  const onDeleteItem = (item: InventoryPickItem) => {
    confirm({
      title: 'Delete Pick Item',
      description:
        'Are you sure you want to remove this item from the pick? This will show the line item as still needing to be purchased/fulfilled.',
    }).then(() => {
      axios.delete(`/api/inventory-pick-items/${item.id}`).then(() => {
        onReload();
      });
    });
  };

  const onChangeLocation = (item: InventoryPickItem) => {
    prompt({
      title: 'Pull From Another Location',
      fields: [FieldFactory.custom('location', ChooseLocationForVariant)],
      schema: z.object({
        location: genericModelReferenceSchema,
        variant_id: z.number(),
      }),
      initialValues: {
        location: item.location || undefined,
        variant_id: item.variant_id,
      },
      onSubmit: (v) =>
        axios.put(`/api/inventory-pick-items/${item.id}`, { location_id: v.location.id }),
    }).then(() => {
      onReload();
    });
  };

  const onToggleItem = (item: InventoryPickItem) => (e: ChangeEvent<HTMLInputElement>) => {
    const promise = e.target.checked
      ? confirm({
          title: 'Confirm Item Picked',
          description: (
            <div>
              Please confirm you have picked <b>{item.sku}</b> from <b>{item.location?.path}</b>.
            </div>
          ),
        })
      : confirm({
          title: 'Unpick Item',
          description: 'This will mark this item as no longer picked.',
        });

    promise.then(() => {
      axios.post(`/api/inventory-pick-items/${item.id}/pick`).then(() => {
        onReload();
      });
    });
  };

  if (items.length === 0) {
    return (
      <CardContent>
        <Typography variant="body1" color="textSecondary">
          No items found
        </Typography>
      </CardContent>
    );
  }

  return (
    <TableContainer>
      <Table style={{ whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            {status === 'picking' && <TableCell padding="checkbox" />}
            <TableCell>SKU</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Qty</TableCell>
            {showItemStatus && (
              <TableCell>{status === 'issued' ? 'Available' : 'Location'}</TableCell>
            )}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <PickItemRow key={item.id} picked={status === 'picking' && !!item.picked_at}>
              {status === 'picking' && (
                <TableCell padding="checkbox">
                  <Checkbox checked={!!item.picked_at} onChange={onToggleItem(item)} />
                </TableCell>
              )}
              <TableCell>
                <SkuLabel variant={item.variant} withoutDescription />
              </TableCell>
              <TableCell style={{ whiteSpace: 'initial', minWidth: 200 }}>
                {item.description}
              </TableCell>
              <TableCell>
                <TextButton onClick={() => onUpdateQty(item)} disabled={status !== 'issued'}>
                  {item.qty}
                </TextButton>
              </TableCell>
              {showItemStatus && (
                <TableCell>
                  {item.location && status !== 'issued' ? (
                    <InventoryLocationLabel location={item.location} />
                  ) : (
                    <PickItemAvailablility item={item} />
                  )}
                </TableCell>
              )}
              <TableCell>
                {item.location && status === 'picking' ? (
                  <Tooltip title="Pull From Another Location">
                    <IconButton onClick={() => onChangeLocation(item)} size="large">
                      <SwapHoriz fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="View SKU Locations">
                    <IconButton onClick={() => setViewing(item)} size="large">
                      <ViewList fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                {status === 'issued' && (
                  <IconButton onClick={() => onDeleteItem(item)} size="large">
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
            </PickItemRow>
          ))}
        </TableBody>
      </Table>

      <ClosableDrawer
        open={!!viewing}
        onClose={() => setViewing(undefined)}
        title="Inventory Locations for SKU"
      >
        {viewing && (
          <>
            <Box mb={2}>
              <Typography variant="h6">SKU: {viewing.sku}</Typography>
              <Typography>{viewing.description}</Typography>
            </Box>
            <Box mx={-2}>
              <InventoryLevels
                filterKey="variant_id"
                filterValue={viewing.variant_id}
                label={viewing.sku}
              />
            </Box>
          </>
        )}
      </ClosableDrawer>
    </TableContainer>
  );
}
