import { Add } from '@mui/icons-material';
import {
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { NumberParam, useQueryParam } from 'use-query-params';
import { z } from 'zod';
import { useCreateIssue, useGetIssues } from '@/api/issues';
import { FieldFactory } from '@/classes';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import StatusChip from '@/components/Shared/StatusChip';
import { ISSUE_STATUS_COLORS } from '@/constants';
import { Issue } from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';
import IssueDialog from './IssueDialog';

export default function IssuesCard({
  issuableType,
  issuableId,
  createType,
}: {
  issuableType: Issue['issuable_type'];
  issuableId: number;
  createType?: Issue['type'];
}) {
  const [selectedIssue, setSelectedIssue] = useQueryParam('selected_issue', NumberParam);
  const { prompt } = useDialogs();

  const { data: issues = [], isLoading } = useGetIssues(issuableType, issuableId);
  const createRequest = useCreateIssue(issuableType, issuableId);

  const handleCreate = () => {
    if (!createType) return;

    prompt({
      title: 'Create Issue',
      fields: [FieldFactory.textarea('details')],
      schema: z.object({
        details: z.string(),
      }),
      onSubmit: (v) => createRequest.mutateAsync({ ...v, type: createType }),
    });
  };

  const action = createType ? (
    <IconButton onClick={handleCreate} size="large">
      <Add />
    </IconButton>
  ) : null;

  return (
    <CardWithGutter>
      <CardHeader title="Issues" action={action} />

      {!isLoading && issues.length === 0 && (
        <Box color="text.secondary" px={2}>
          There are no issues.
        </Box>
      )}

      {!isLoading ? (
        <List>
          {issues.map((issue) => (
            <ListItemButton key={issue.id} onClick={() => setSelectedIssue(issue.id)}>
              <ListItemText primary={issue.label} secondary={issue.preview} />
              <ListItemSecondaryAction>
                <StatusChip status={issue.status} colors={ISSUE_STATUS_COLORS} />
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      ) : (
        <CardContent>
          <CircularProgress />
        </CardContent>
      )}

      <IssueDialog
        open={!!selectedIssue}
        onClose={() => setSelectedIssue(undefined)}
        issue={issues.find((i) => i.id === selectedIssue)}
      />
    </CardWithGutter>
  );
}
