import { ChangeEvent, useCallback, useState } from 'react';
import { Inventory, Undo } from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import has from 'lodash/has';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import sumBy from 'lodash/sumBy';
import { z } from 'zod';
import { useCreateReceiving, useUnreceivePoItem } from '@/api/receiving';
import { FieldFactory } from '@/classes';
import OrderLabel from '@/components/Orders/OrderLabel';
import Can from '@/components/Permissions/Can';
import DescriptionCell from '@/components/Purchasing/DescriptionCell';
import PurchaseOrderItemQtyReceived from '@/components/Receiving/PurchaseOrderItemQtyReceived';
import GroupedPaginatedTable from '@/components/Shared/GroupedPaginatedTable';
import ArrayBullets from '@/components/Text/ArrayBullets';
import TextLink from '@/components/Text/TextLink';
import { PurchaseOrderItem, ReceivingPayload } from '@/types';
import { useHasPermission } from '@/utils/hooks/permissions';
import useDialogs from '@/utils/hooks/useDialogs';
import numString from '@/utils/numString';
import PrintMenu from '../Print/PrintMenu';

export function getReceivingFields() {
  return [
    FieldFactory.images('images'),
    FieldFactory.list('barcodes', [FieldFactory.text('barcode').withColumnSpan(10)]).with({
      addNewLabel: 'Add Barcode +',
      addNewValue: {
        barcode: '',
      },
    }),
  ];
}

function PoItemColumns({ item }: { item: PurchaseOrderItem }) {
  return (
    <>
      <DescriptionCell title={item.description || ''}>{item.description}</DescriptionCell>
      <TableCell>{item.size}</TableCell>
      <DescriptionCell title={[item.number, item.color, item.size].join(' - ')}>
        {item.variant ? (
          <Tooltip title={<ArrayBullets elements={[item.number, item.color, item.size]} />}>
            <span>{item.variant.sku}</span>
          </Tooltip>
        ) : (
          <ArrayBullets elements={[item.number, item.color, item.size]} />
        )}
      </DescriptionCell>
    </>
  );
}

export default function ReceivePurchaseOrder({
  title,
  items,
  toReceive,
  id,
  type,
}: {
  title: string;
  items: PurchaseOrderItem[];
  toReceive?: boolean;
  id?: number;
  type?: 'order' | 'purchase_order';
}) {
  const [selected, setSelected] = useState<Record<number, number>>({});
  const { prompt, confirm } = useDialogs();
  const hasPermission = useHasPermission();
  const createRequest = useCreateReceiving();
  const unreceiveRequest = useUnreceivePoItem();

  const toggleAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(
        items.reduce(
          (agg, i) => {
            agg[i.id] = i.qty - i.qty_received;
            return agg;
          },
          {} as Record<number, number>,
        ),
      );
    } else {
      setSelected({});
    }
  };

  const toggleOne = (item: PurchaseOrderItem, e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => ({
        ...prev,
        [item.id]: item.qty - item.qty_received,
      }));
    } else {
      setSelected((prev) => omit(prev, item.id));
    }
  };

  const handleQtyChange = (item: PurchaseOrderItem, value: number | string) => {
    setSelected((prev) => ({
      ...prev,
      [item.id]: Number(value),
    }));
  };

  const numSelected = keys(selected).length;

  const receive = () => {
    const payload: ReceivingPayload = {
      [`${type}_id`]: id,
      items: Object.entries(selected).map(([k, v]) => ({
        purchase_order_item_id: Number(k),
        qty_received: v,
      })),
    };
    prompt({
      title: `Receive ${numSelected} Items`,
      description:
        'Optionally upload image(s) of the packing list and/or scan the carrier barcode(s)',
      submitText: 'Receive',
      initialValues: {
        images: [],
        barcodes: [],
      },
      schema: z.object({
        images: z.array(
          z.object({
            url: z.string(),
          }),
        ),
        barcodes: z.array(
          z.object({
            barcode: z.string(),
          }),
        ),
      }),
      fields: getReceivingFields(),
      onSubmit: (v) =>
        createRequest.mutateAsync({
          ...payload,
          barcodes: v.barcodes.map((b) => b.barcode),
          images: v.images.map((i) => i.url),
        }),
    }).then(() => {
      setSelected([]);
    });
  };

  const unreceive = (item: PurchaseOrderItem) => {
    confirm({
      title: `Unreceive ${item.qty_received} Items`,
      description: 'Are you sure you want to unreceive these items?',
    }).then(() => {
      unreceiveRequest.mutate(item.id);
    });
  };

  const canReceive = toReceive && hasPermission('write:receivings');

  const renderRow = (item: PurchaseOrderItem, key: number, groupLength: number) => (
    <TableRow key={item.id}>
      {canReceive && (
        <TableCell padding="checkbox">
          <Checkbox checked={has(selected, item.id)} onChange={(e) => toggleOne(item, e)} />
        </TableCell>
      )}
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <OrderLabel orderId={item.order_id} />
        {item.order_id && <PrintMenu id={item.order_id} model="order" size="small" />}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <TextLink to={`/purchase-orders/${item.purchase_order_id}`}>
          {item.purchase_order_id}
        </TextLink>
        {item.is_inventory && (
          <Tooltip title="This item is going into inventory">
            <Inventory color="primary" fontSize="small" sx={{ verticalAlign: 'bottom', ml: 1 }} />
          </Tooltip>
        )}
      </TableCell>
      {groupLength > 1 ? <TableCell colSpan={3} /> : <PoItemColumns item={item} />}
      <TableCell>{item.qty}</TableCell>
      <TableCell>
        {item.qty_received !== 0 ? <PurchaseOrderItemQtyReceived item={item} /> : item.qty_received}
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" gap={1}>
          {item.id in selected && (
            <TextField
              onChange={(e) => handleQtyChange(item, e.target.value)}
              value={selected[item.id]}
              style={{ minWidth: 50 }}
              size="small"
              inputProps={{
                type: 'number',
                step: 1,
                [item.qty < 0 ? 'min' : 'max']: item.qty - item.qty_received,
                [item.qty < 0 ? 'max' : 'min']: 0,
              }}
            />
          )}
          {Number(item.qty_received) !== 0 && (
            <Tooltip title="Unreceive Items">
              <IconButton size="small" onClick={() => unreceive(item)}>
                <Undo fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );

  const groupItemsBy = useCallback(
    (i: PurchaseOrderItem) => [i.number, i.color, i.size, i.description].join('|'),
    [],
  );

  return (
    <>
      <CardHeader
        title={title}
        action={
          numSelected > 0 && (
            <Button onClick={receive} variant="contained">
              Receive
            </Button>
          )
        }
      />
      {items.length === 0 ? (
        <CardContent>
          <Typography variant="body1" color="textSecondary">
            No items found
          </Typography>
        </CardContent>
      ) : (
        <GroupedPaginatedTable
          items={items}
          searchable={['number', 'color', 'size', 'description']}
          header={
            <TableRow>
              {toReceive && (
                <Can permission="write:receivings">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={numSelected === items.length}
                      indeterminate={numSelected > 0 && numSelected < items.length}
                      onChange={toggleAll}
                    />
                  </TableCell>
                </Can>
              )}

              <TableCell>Order</TableCell>
              <TableCell>PO</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Qty Pur</TableCell>
              <TableCell>Qty Rec</TableCell>
              <TableCell />
            </TableRow>
          }
          renderRow={renderRow}
          groupBy={groupItemsBy}
          renderGroupHeader={(items) => (
            <TableRow sx={{ bgcolor: 'action.hover' }}>
              {canReceive && <TableCell padding="checkbox" />}
              <TableCell colSpan={2}>{numString(items.length, 'items')}</TableCell>
              <PoItemColumns item={items[0]} />
              <TableCell>{sumBy(items, 'qty')}</TableCell>
              <TableCell>{sumBy(items, 'qty_received')}</TableCell>
              <TableCell />
            </TableRow>
          )}
        />
      )}
    </>
  );
}
