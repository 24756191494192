import { SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Close, OpenInNew } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemBaseProps,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { FieldValues, UseControllerReturn } from 'react-hook-form';
import { Resource } from '@/classes';
import RelationTableField from '@/classes/Fields/RelationTableField';
import { CellProps, FieldProps } from '@/classes/types';
import { OnClickProps } from '@/classes/types';
import InputWrapper from '@/components/Form/InputWrapper';
import Text from '@/components/Text/Text';
import { ResourceName, ResourceValueMap } from '@/resources/types';
import useDebounce from '@/utils/hooks/useDebounce';
import useGetResource, { useResource } from '@/utils/hooks/useGetResource';
import useOnClickProps from '@/utils/hooks/useOnClickProps';
import wrap from '@/utils/wrap';
import Field from '../Field';

function Relation<K extends ResourceName>({
  field,
  fieldModel,
  fieldState,
}: FieldProps<RelationField<K>>) {
  const multiple = fieldModel.hasMany;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const onClickProps = useOnClickProps();
  const {
    dialogs: { prompt },
  } = onClickProps;
  const otherQueryParams = fieldModel.getRequestParams(onClickProps);
  const value = useMemo(() => (multiple ? wrap(field.value) : field.value), [field.value]);
  const getResource = useGetResource();

  // Overrides on resource for this fieldModel
  const overrides = pickBy(
    pick(fieldModel, [
      'getIndexRequest',
      'getLabel',
      'getValue',
      'getSubtitle',
      'getSecondaryAction',
      'getAvatar',
    ]),
  );

  const resource = getResource(fieldModel.resource) as Resource<ResourceValueMap[K]>;
  Object.assign(resource, overrides);
  const { staticOptions } = resource;
  const pk = resource.primaryKey;
  const isAdvancedDisplay =
    !multiple && (resource.getSubtitle || resource.getAvatar || resource.getSecondaryAction);

  const { data: optionsFromApi = [], isFetching } = useQuery(
    ['options', fieldModel.resource, JSON.stringify(otherQueryParams), debouncedQuery],
    () =>
      resource
        .getIndexRequest({ query: debouncedQuery, ...otherQueryParams })
        .then(({ data }) => data.data),
    { enabled: open && !staticOptions },
  );

  const onCreate = () => {
    prompt({
      title: `Create ${resource.singularName}`,
      fields: resource.fields,
      schema: resource.createSchema,
      onSubmit: (v) => resource.getStoreRequest(v).then(({ data }) => data),
    }).then((data) => {
      field.onChange(multiple ? [...value, data] : data);
    });
  };

  const renderOption = (listItemProps: Partial<ListItemBaseProps>, o: FieldValues) => {
    if (o.id === 'create') {
      return (
        <ListItemButton onClick={onCreate} key="create">
          <ListItemText>{o.label}</ListItemText>
        </ListItemButton>
      );
    }
    const option = o as ResourceValueMap[K];
    return (
      <ListItem {...listItemProps} component="div" key={option[pk] as number} dense disableGutters>
        {resource.getAvatar && (
          <ListItemAvatar
            sx={{
              '& .MuiAvatar-root': {
                width: 36,
                height: 36,
              },
            }}
          >
            {resource.getAvatar(option)}
          </ListItemAvatar>
        )}
        <ListItemText
          sx={{ my: 0 }}
          primary={resource.getLabel(option)}
          secondary={resource.getSubtitle && resource.getSubtitle(option)}
        />
        {resource.getSecondaryAction && (
          <ListItemSecondaryAction>{resource.getSecondaryAction(option)}</ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };

  const handleChange = (e: SyntheticEvent, newValue: any) => {
    field.onChange(newValue);
  };

  const renderTextField = (params: Partial<TextFieldProps>) => (
    <TextField
      {...fieldModel.getTextFieldProps(fieldState)}
      {...params}
      inputRef={inputRef}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isFetching && <CircularProgress color="inherit" size={20} />}
            {params.InputProps?.endAdornment}
          </>
        ),
      }}
    />
  );

  const renderSelect = () => (
    <InputWrapper fieldState={fieldState} fieldModel={fieldModel}>
      <Select
        open={false}
        label={fieldModel.label}
        onOpen={(e) => {
          e.preventDefault();
          setOpen(true);
          setTimeout(() => {
            inputRef.current?.select();
          }, 100);
        }}
        sx={{
          '& .MuiSelect-select': {
            py: 0,
          },
        }}
        value={field.value}
        renderValue={(v) =>
          renderOption(
            {
              sx: {
                py: 0,
                minHeight: 44,
              },
            },
            v,
          )
        }
      />
      {fieldModel.getLink && field.value ? (
        <IconButton
          component="a"
          href={fieldModel.getLink(field.value)}
          target="_blank"
          size="small"
          sx={{
            position: 'absolute',
            right: 64,
            top: 'calc(50% - 15px)',
          }}
        >
          <OpenInNew fontSize="small" />
        </IconButton>
      ) : null}
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          right: 32,
          top: 'calc(50% - 15px)',
        }}
        onClick={() => field.onChange(null)}
      >
        <Close fontSize="small" />
      </IconButton>
    </InputWrapper>
  );

  const renderInput = open || !field.value || !isAdvancedDisplay ? renderTextField : renderSelect;

  const getOptionLabel = useCallback(
    (o: FieldValues) => {
      if (!o) {
        return '';
      }
      if (o.id === 'create') {
        return o.label;
      }
      const option = o as ResourceValueMap[K];
      return resource.getLabel(option);
    },
    [resource],
  );

  const filterOptions = staticOptions ? undefined : (array: Record<string, any>[]) => array;

  let options: ResourceValueMap[K][] | undefined = staticOptions;

  if (!staticOptions) {
    if (!isFetching && optionsFromApi.length === 0 && fieldModel.createsOptions && query) {
      options = [
        {
          id: 'create',
          label: `Create "${query}"`,
        } as ResourceValueMap[K],
      ];
    } else {
      options = optionsFromApi;
    }
  }

  const onInputChange = useCallback(
    (e: SyntheticEvent, q: string) => {
      setQuery(q);
    },
    [setQuery],
  );

  return (
    <Autocomplete
      open={open}
      multiple={multiple}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, v) => option[pk] === v[pk]}
      options={options || []}
      filterOptions={filterOptions}
      noOptionsText={isFetching ? 'Loading...' : `No ${resource.name.toLowerCase()} found`}
      onChange={handleChange}
      value={value || null}
      disabled={fieldModel.disabled}
      readOnly={Boolean(fieldModel.readOnly)}
      inputValue={query}
      onInputChange={onInputChange}
      renderInput={renderInput}
      size={isAdvancedDisplay ? 'medium' : fieldModel.size}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
    />
  );
}

export function RelationCell<K extends ResourceName>({
  value,
  fieldModel,
}: CellProps<RelationField<K> | RelationTableField<K>>) {
  const resource = useResource(fieldModel.resource);
  if (value == null || value === '') {
    return null;
  }
  if (fieldModel instanceof RelationField && fieldModel.hasMany) {
    return (
      <span>
        {wrap(value)
          .map((v) => {
            const label = resource.getLabel(v);
            return typeof label === 'object' ? resource.getTitle(v) : label;
          })
          .join(', ')}
      </span>
    );
  }
  if (resource.getSubtitle) {
    return <Text primary={resource.getLabel(value)} secondary={resource.getSubtitle(value)} />;
  }
  return <span>{resource.getLabel(value)}</span>;
}

export default class RelationField<K extends ResourceName> extends Field {
  resource: K;
  hasMany = false;
  createsOptions = false;
  requestParams: Record<string, any> | ((props: OnClickProps) => Record<string, any>) = {};
  getIndexRequest?: Resource<ResourceValueMap[K]>['getIndexRequest'];
  getLabel?: Resource<ResourceValueMap[K]>['getLabel'];
  getValue?: Resource<ResourceValueMap[K]>['getValue'];
  getSubtitle?: Resource<ResourceValueMap[K]>['getSubtitle'];
  getSecondaryAction?: Resource<ResourceValueMap[K]>['getSecondaryAction'];
  getAvatar?: Resource<ResourceValueMap[K]>['getAvatar'];
  getLink?: (value: ResourceValueMap[K]) => string;

  constructor(name: string, resource: K) {
    super(name);
    this.resource = resource;
  }

  getDefaultFilterKey(): string {
    const key = `${this.name}.id`;
    if (this.hasMany) {
      return `filter[${key}][in]`;
    }
    return `filter[${key}]`;
  }

  renderEditComponent(props: UseControllerReturn) {
    return <Relation<K> {...props} fieldModel={this} />;
  }

  renderCell(value: any) {
    return <RelationCell<K> value={value} fieldModel={this} />;
  }

  getRequestParams(props: OnClickProps): Record<string, any> {
    return typeof this.requestParams === 'function'
      ? this.requestParams(props)
      : this.requestParams;
  }

  withRequestParams(params: Record<string, any> | ((props: OnClickProps) => Record<string, any>)) {
    this.requestParams = params;
    return this;
  }
}
