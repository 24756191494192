import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Kit, KitItem, KitPayload, OrderItem } from '@/types';

function useOnKitItemUpdate() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['orderKitItems']);
    queryClient.invalidateQueries(['kitsForAddress']);
  }, [queryClient]);
}

export function useKitsForAddress(addressId: number) {
  return useQuery(['kitsForAddress', addressId], () =>
    axios.get<{ data: Kit[] }>(`/api/kits?address_id=${addressId}`).then(({ data }) => data.data),
  );
}

export function useOrderKitItems(orderId: number) {
  return useQuery(['orderKitItems', orderId], () =>
    axios
      .get<{
        items: OrderItem[];
        kit_items: OrderItem[];
      }>(`/api/orders/${orderId}/kit-items`)
      .then(({ data }) => [...data.items, ...data.kit_items]),
  );
}

export function useAddOrderItemKits(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation((selected: number[]) =>
    axios.post(`/api/orders/${orderId}/kit-items`, { order_item_ids: selected }).then(() => {
      queryClient.invalidateQueries(['orderKitItems', orderId]);
    }),
  );
}

export function useCreateKitItem(item: OrderItem) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((payload: { kit_id: number; qty: number; drops: string[] }) =>
    axios
      .post<KitItem>('/api/kit-items', {
        order_item_id: item.id,
        ...payload,
      })
      .then(({ data }) => {
        onKitItemUpdate();
        return data;
      }),
  );
}

export function useBulkCreateKitItems(orderId: number, item: OrderItem) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((v: { qty: number }) =>
    axios
      .post('/api/kit-items/bulk-add', {
        order_id: orderId,
        order_item_id: item.id,
        ...v,
      })
      .then(() => {
        onKitItemUpdate();
      }),
  );
}

export function useRemoveKitItem(orderId: number, item: OrderItem) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation(() =>
    axios.delete(`/api/orders/${orderId}/kit-items/${item.id}`).then(() => {
      onKitItemUpdate();
    }),
  );
}

export function useSwitchItem(orderId: number, item: OrderItem) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((toId: number) =>
    axios
      .post('/api/kit-items/switch-item', {
        order_id: orderId,
        from_id: item.id,
        to_id: toId,
      })
      .then(() => {
        onKitItemUpdate();
      }),
  );
}

export function useCreateKit(addressId: number) {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((v: KitPayload) =>
    axios.post<Kit>('/api/kits', { address_id: addressId, ...v }).then(({ data }) => {
      onKitItemUpdate();
      return data;
    }),
  );
}

export function useUpdateKit() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation(({ id, ...v }: KitPayload & { id: number }) =>
    axios.put<Kit>(`/api/kits/${id}`, v).then(({ data }) => {
      onKitItemUpdate();
      return data;
    }),
  );
}

export function useDeleteKit() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((id: number) =>
    axios.delete(`/api/kits/${id}`).then(() => {
      onKitItemUpdate();
    }),
  );
}

export function useUpdateKitItem() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation(
    ({ id, ...v }: { id: number; is_backordered: boolean; qty: number; drops: string[] }) =>
      axios.put<KitItem>(`/api/kit-items/${id}`, v).then(({ data }) => {
        onKitItemUpdate();
        return data;
      }),
  );
}

export function useDeleteKitItem() {
  const onKitItemUpdate = useOnKitItemUpdate();

  return useMutation((id: number) =>
    axios.delete(`/api/kit-items/${id}`).then(() => {
      onKitItemUpdate();
    }),
  );
}
