import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Box, Button, Card, CardHeader, Table, TableBody, TableContainer } from '@mui/material';
import arrayMove from 'array-move';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { useCreateOrderDesign, useSortOrderDesigns } from '@/api/designs';
import { FieldFactory, GroupLayout } from '@/classes';
import { FieldProps } from '@/classes/types';
import InkChangeField from '@/components/Designs/InkChangeField';
import OrderDesignListItem from '@/components/Designs/OrderDesignListItem';
import {
  DesignLayoutWithProofs,
  genericModelReferenceSchema,
  Order,
  orderDesignCreatePayloadSchema,
} from '@/types';
import { useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';

function ExistingDesignField({ fieldModel, ...props }: FieldProps) {
  const form = useFormContext<{ all_customers?: boolean; include_incomplete?: boolean }>();
  const allCustomers = form.watch('all_customers');
  const includeIncomplete = form.watch('include_incomplete');
  const customerId = useRecord<Order>().customer.id;

  const requestParams: Record<string, string> = {};

  if (!allCustomers) {
    requestParams.customer_id = String(customerId);
  }
  if (!includeIncomplete) {
    requestParams.complete = '1';
  }

  const newField = FieldFactory.belongsTo(fieldModel.name, 'designs').withRequestParams(
    requestParams,
  );
  return (
    <Box width="500px" maxWidth="100%">
      {newField.renderEditComponent(props)}
    </Box>
  );
}

export default function OrderDesignsList({
  layout,
  canEdit,
}: {
  layout: DesignLayoutWithProofs;
  canEdit: boolean;
}) {
  const { prompt } = useDialogs();

  const createOrderDesignRequest = useCreateOrderDesign(layout);
  const sortOrderDesignsRequest = useSortOrderDesigns(layout);

  const addNewToLayout = () => {
    prompt({
      title: `Request New Design For ${layout.increment_id}`,
      fields: [
        FieldFactory.text('name').withSize('medium'),
        FieldFactory.belongsTo('decoration_type', 'decorationTypes'),
      ],
      schema: orderDesignCreatePayloadSchema
        .omit({
          decoration_type_id: true,
          design_layout_id: true,
        })
        .extend({
          decoration_type: genericModelReferenceSchema,
        }),
      onSubmit: (v) =>
        createOrderDesignRequest.mutateAsync({
          ...v,
          decoration_type_id: v.decoration_type.id,
        }),
    });
  };

  const addExistingToLayout = () => {
    prompt({
      title: `Add Existing Design To ${layout.increment_id}`,
      fields: [
        FieldFactory.custom('design', ExistingDesignField),
        new GroupLayout('', [
          FieldFactory.boolean('all_customers', 'Include designs from other customers'),
          FieldFactory.boolean('include_incomplete', 'Include incomplete designs'),
        ]),
        FieldFactory.custom('ink_changes', InkChangeField),
      ],
      initialValues: {
        all_customers: false,
        include_incomplete: false,
        ink_changes: [],
      },
      schema: orderDesignCreatePayloadSchema.pick({ ink_changes: true }).extend({
        design: genericModelReferenceSchema,
        all_customers: z.boolean().nullish(),
        include_incomplete: z.boolean().nullish(),
      }),
      onSubmit: (v) =>
        createOrderDesignRequest.mutateAsync({
          design_id: v.design.id,
          ink_changes: v.ink_changes,
        }),
    });
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }
    const oldIndex = layout.order_designs.findIndex((od) => od.id === active.id);
    const newIndex = layout.order_designs.findIndex((od) => od.id === over.id);
    sortOrderDesignsRequest.mutate(arrayMove(layout.order_designs, oldIndex, newIndex));
  };

  return (
    <Card>
      <CardHeader
        title={`Designs for ${layout.increment_id}`}
        action={
          canEdit && (
            <>
              <Button variant="contained" size="small" onClick={addExistingToLayout} sx={{ mr: 1 }}>
                Add Existing
              </Button>
              <Button size="small" onClick={addNewToLayout}>
                Request New
              </Button>
            </>
          )
        }
      />
      <TableContainer>
        <Table>
          <TableBody>
            <DndContext onDragEnd={onDragEnd}>
              <SortableContext items={layout.order_designs.map((od) => od.id)}>
                {layout.order_designs.map((orderDesign) => (
                  <OrderDesignListItem
                    key={orderDesign.id}
                    artRequestId={undefined}
                    orderDesign={orderDesign}
                    sortable={layout.order_designs.length > 1}
                    canEdit={canEdit}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
