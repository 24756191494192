import { useState } from 'react';
import { Button } from '@mui/material';
import type { UseControllerReturn } from 'react-hook-form';
import { Field, RelationField } from '@/classes';
import { FieldProps } from '@/classes/types';
import ProductFinderDialog from '@/components/Products/ProductFinderDialog';
import { Product } from '@/types';

function ProductInput({ fieldModel, ...props }: FieldProps<ProductField>) {
  const [isAdvanced, setIsAdvanced] = useState(false);

  const search = () => setIsAdvanced(true);

  const onProduct = (product: Product) => {
    setIsAdvanced(false);
    props.field.onChange(product);
  };

  return (
    <div>
      {new RelationField(fieldModel.name, 'products')
        .with({
          requestParams: fieldModel.requestParams,
        })
        .renderEditComponent(props)}

      <Button size="small" onClick={search}>
        Advanced Search
      </Button>

      <ProductFinderDialog
        open={isAdvanced}
        mode="product"
        onSuccess={onProduct}
        onCancel={() => setIsAdvanced(false)}
      />
    </div>
  );
}

export default class ProductField extends Field {
  requestParams: Record<string, any> = {};

  renderEditComponent(props: UseControllerReturn) {
    return <ProductInput {...props} fieldModel={this} />;
  }
}
