import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  LinearProgress,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useCreateShipment } from '@/api/shipping';
import { FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import ConditionallyRenderField from '@/components/Shared/ConditionallyRenderField';
import ChooseRates from '@/components/Shipping/ChooseRates';
import QuoteShipmentForm from '@/components/Shipping/QuoteShipmentForm';
import { useConfig } from '@/contexts/AppConfigContext';
import {
  Shipment,
  ShipmentBasePayload,
  shipmentCreatePayloadSchema,
  ShipmentQuotePayload,
  ShippingDefaultsResponse,
} from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import useDialogs from '@/utils/hooks/useDialogs';
import useThirdPartyAccountsForCustomer from '@/utils/hooks/useThirdPartyAccountsForCustomer';
import { transformLaravelErrors } from '@/utils/validator';

function isPickup(method: string | null | undefined) {
  const methodString = (method || '').toLowerCase();
  return methodString.includes('pickup') || methodString === 'delivery by sales';
}

export default function ShipActions({
  payload,
  customerId,
  title,
  qty,
  onSuccess,
  addressMethod,
  shipButtonProps = { children: 'Ship' },
  size = 'medium',
}: {
  payload: ShipmentBasePayload;
  customerId: number | undefined;
  title: string;
  qty: number;
  onSuccess: (s: Shipment) => void;
  addressMethod?: string | null;
  shipButtonProps?: ButtonProps;
  size?: 'small' | 'medium';
}) {
  const [shipping, setShipping] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [step, setStep] = useState(0);
  const { carriers } = useConfig();
  const { prompt } = useDialogs();
  const [values, setValues] = useState<ShipmentQuotePayload>();

  const thirdPartyAccounts = useThirdPartyAccountsForCustomer(shipping && customerId);
  const createShipmentRequest = useCreateShipment();

  const defaultsRequest = useMutation((params: ShipmentBasePayload) =>
    axios
      .get<ShippingDefaultsResponse>(`/api/shipments/defaults`, {
        params,
      })
      .then(({ data }) => data),
  );

  useEffect(() => {
    if (shipping) {
      setStep(0);
      defaultsRequest.mutateAsync(payload).then((defaults) => {
        setValues({
          ...payload,
          from_address_id: defaults.from_address.id,
          require_signature: defaults.require_signature,
          service_level: defaults.service_level,
          third_party_account_id: defaults.third_party_account_id,
          customs: defaults.customs,
          parcels: defaults.parcels,
        });
      });
    }
  }, [shipping, JSON.stringify(payload)]);

  const getQuotes = () => {
    setErrors({});
    setStep(1);
  };

  const onMarkAsShipped = () => {
    prompt({
      title,
      description: `You will be marking ${qty} items as shipped. You can optionally add tracking information if you have it.`,
      fields: [
        FieldFactory.select('carrier', carriers),
        new ConditionallyRenderField(
          'tracking_number',
          FieldFactory.text('tracking_number'),
          (v) => v.carrier !== 'courier',
        ),
        FieldFactory.curr('cost').withHelp('Only add if shipping cost is not on a PO'),
        FieldFactory.file('file'),
      ],
      schema: shipmentCreatePayloadSchema.pick({
        carrier: true,
        tracking_number: true,
        cost: true,
        file: true,
      }),
      onSubmit: (v) =>
        createShipmentRequest.mutateAsync({
          ...payload,
          ...v,
        }),
    }).then(onSuccess);
  };

  const onRatesError = (e: AxiosError) => {
    const res = e.response?.data;
    if (res && typeof res === 'object' && 'errors' in res) {
      setErrors(transformLaravelErrors((res.errors || {}) as Record<string, string[]>));
    }
    setStep(0);
  };

  const onChooseRate = (rateId: string) => {
    createShipmentRequest
      .mutateAsync({
        ...payload,
        rate_id: rateId,
      })
      .then((shipment) => {
        onSuccess(shipment);
        setShipping(false);
        setTimeout(() => {
          window.open(getApiUrl(`/api/shipments/${shipment.id}/label`));
        });
      });
  };

  return (
    <Box display="inline-flex" alignItems="center">
      {payload.address_id && (
        <Can permission="write:shipments">
          <Button size={size} onClick={onMarkAsShipped} sx={{ mr: 1 }}>
            Mark Shipped
          </Button>
        </Can>
      )}

      {!isPickup(addressMethod) && (
        <Can permission="shipments:purchase">
          <Button
            size={size}
            onClick={() => setShipping(true)}
            variant="contained"
            {...shipButtonProps}
          />
        </Can>
      )}

      <ClosableDrawer open={shipping} onClose={() => setShipping(false)} title={title}>
        <Stepper activeStep={step} sx={{ mt: -2, mb: 2 }}>
          <Step>
            <StepButton onClick={() => setStep(0)}>Shipment Info</StepButton>
          </Step>
          <Step>
            <StepLabel>Choose Rate</StepLabel>
          </Step>
        </Stepper>

        {!defaultsRequest.data || !values ? (
          <LinearProgress />
        ) : (
          <>
            {step === 0 && (
              <QuoteShipmentForm
                defaults={defaultsRequest.data}
                values={values}
                setValues={setValues}
                onSubmit={getQuotes}
                errors={errors}
                thirdPartyAccounts={thirdPartyAccounts}
              />
            )}

            {step === 1 && (
              <ChooseRates
                values={values}
                onChoose={onChooseRate}
                isChoosing={createShipmentRequest.isLoading}
                onError={onRatesError}
              />
            )}
          </>
        )}
      </ClosableDrawer>
    </Box>
  );
}
