import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, CardHeader, Checkbox, TextField } from '@mui/material';
import sumBy from 'lodash/sumBy';
import { useGetShippableItems } from '@/api/orderItems';
import { useCreateShipment } from '@/api/shipping';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import { useShowLoading } from '@/contexts/AppContext';
import { Order, OrderItem, ShipmentItemPayload } from '@/types';
import { useOnReloadRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import { buildIncrementId } from '@/utils/notes';
import numString from '@/utils/numString';
import {
  canShipOrderItem,
  getKitQty,
  isInventoryKit,
  orderItemToShipmentItem,
} from '@/utils/shipping';

export default function OrderShippingInventory({ order }: { order: Order }) {
  const orderId = order.id;
  const isKit = isInventoryKit(order.shipping_mode);
  const [selected, setSelected] = useState<ShipmentItemPayload[]>([]);
  const { confirm } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();
  const createShipmentRequest = useCreateShipment();

  useEffect(() => {
    setSelected([]);
  }, [orderId]);

  const { data: items = [], refetch, isFetching } = useGetShippableItems(orderId);
  const shippableItems = items.filter(canShipOrderItem);

  const onAddToInventory = () => {
    let title = `Add ${numString(sumBy(selected, 'qty_shipped'), 'Items')} to Inventory`;
    let description =
      'This will mark the items as shipped and add them to the default inventory location. Are you sure you want to proceed?';

    if (isKit) {
      const kitQty = getKitQty(selected, shippableItems);
      if (typeof kitQty === 'object') {
        alert(
          `The total quantity selected for ${kitQty.item} and the quantities per kit cannot create an even amount of kits. Please adjust either the quantities selected or the quantity per kit values.`,
        );
        return;
      }
      title = `Add ${numString(kitQty as number, 'Kits')} to Inventory`;
      description =
        'This will mark the kits as shipped and add them to the default inventory location. Are you sure you want to proceed?';
    }

    confirm({ title, description }).then(() => {
      showLoading(
        createShipmentRequest.mutateAsync({
          shippable_id: orderId,
          shippable_type: 'order',
          items: selected,
        }),
      ).then(() => {
        setSelected([]);
        onReload();
      });
    });
  };

  const handleCheck = (item: OrderItem) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected([...selected, orderItemToShipmentItem(item)]);
    } else {
      setSelected(selected.filter((s) => s.order_item_id !== item.id));
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(shippableItems.map(orderItemToShipmentItem));
    } else {
      setSelected([]);
    }
  };

  const handleSelectedChange =
    (id: number, field: keyof ShipmentItemPayload) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(
        selected.map((item) => {
          if (item.order_item_id === id) {
            return {
              ...item,
              [field]: e.target.value,
            };
          }
          return item;
        }),
      );
    };

  const actions = (
    <div>
      {selected.length > 0 && (
        <Button onClick={onAddToInventory} variant="contained" sx={{ ml: 1 }}>
          Add to Inventory
        </Button>
      )}
    </div>
  );

  return (
    <Card>
      <CardHeader title="Items for Inventory" action={actions} />

      <PaginatedTable
        size="small"
        searchable={['number', 'color', 'size', 'description', 'design_layout_id']}
        onReload={refetch}
        isFetching={isFetching}
        rows={items}
        columns={[
          {
            id: 'checkbox',
            enableSorting: false,
            enableHiding: false,
            header: () =>
              shippableItems.length > 0 ? (
                <Checkbox
                  onChange={(e) => handleCheckAll(e)}
                  checked={selected.length === shippableItems.length}
                  indeterminate={selected.length > 0 && selected.length < shippableItems.length}
                />
              ) : null,
            cell: ({ row }) =>
              canShipOrderItem(row.original) && (
                <Checkbox
                  onChange={handleCheck(row.original)}
                  checked={selected.some((i) => i.order_item_id === row.original.id)}
                />
              ),
          },
          { accessorKey: 'number', header: 'Item #' },
          { accessorKey: 'color', header: 'Color' },
          { accessorKey: 'size', header: 'Size' },
          { accessorKey: 'description', header: 'Description' },
          {
            accessorKey: 'design_layout_id',
            header: 'Design Layout',
            cell: ({ getValue }) => {
              const layoutId = getValue<number | null>();
              return layoutId ? buildIncrementId('LAY', layoutId) : 'Blank';
            },
          },
          { accessorKey: 'qty', header: 'Qty Req' },
          items.some((i) => i.qty_scrapped > 0) && {
            accessorKey: 'qty_scrapped',
            header: 'Qty Scrapped',
          },
          { accessorKey: 'qty_shipped', header: 'Qty Shipped' },
          {
            id: 'input',
            header: 'Qty to Ship',
            enableSorting: false,
            enableHiding: false,
            cell: ({ row }) => {
              const checked = selected.find((i) => i.order_item_id === row.original.id);
              return checked ? (
                <TextField
                  size="small"
                  style={{ width: 80 }}
                  onChange={handleSelectedChange(row.original.id, 'qty_shipped')}
                  value={checked.qty_shipped}
                  type="number"
                />
              ) : (
                <div style={{ width: 100 }}>&nbsp;</div>
              );
            },
          },
        ]}
      />
    </Card>
  );
}
