import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import { useUpdateOrderDesignImprints } from '@/api/designs';
import DrawerButtons from '@/components/Form/DrawerButtons';
import ItemDesignTable from '@/components/Production/ItemDesignTable';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import {
  OrderDesign,
  OrderItem,
  OrderItemDesign,
  PartiallyRequired,
  ArtRequest,
  Quote,
  Order,
} from '@/types';
import { useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';

export default function ImprintsDrawer({
  orderDesign,
  open,
  onClose,
}: {
  orderDesign: OrderDesign;
  open: boolean;
  onClose: () => void;
}) {
  const record = useRecord<PartiallyRequired<ArtRequest, 'order'> | Quote | Order>();
  const resource = 'converted_order_id' in record ? 'quotes' : 'orders';
  const orderId = 'order' in record ? record.order.id : record.id;

  const { confirm } = useDialogs();
  const updateImprintsRequest = useUpdateOrderDesignImprints(orderDesign);

  const onSave = async (items: OrderItem[]) => {
    const payload = flow(
      (items) => keyBy(items, 'id'),
      (dict) => pickBy(dict, (i) => i.order_design_ids[orderDesign.id]),
      (dict) => mapValues(dict, (i) => i.order_design_ids[orderDesign.id]),
    )(items) as Record<number, Partial<OrderItemDesign>>;

    if (Object.keys(payload).length > 4) {
      await confirm({
        title: 'Multiple Sizes',
        description: `
          You are applying this design to more than 4 sizes. 
          Your order might require more than one size of this logo to ensure the image properly fits on the garment.
          `,
        color: 'warning',
        submitText: 'OK',
        cancelText: 'Cancel',
      });
    }

    await updateImprintsRequest.mutateAsync(payload);
    onClose();
  };

  const [items, setItems] = useState<OrderItem[]>([]);

  const itemsRequest = useMutation(() =>
    axios
      .get<{ data: OrderItem[] }>(`/api/${resource}/${orderId}/items`)
      .then(({ data }) => data.data),
  );

  useEffect(() => {
    itemsRequest.mutateAsync().then((items) => {
      setItems(items.filter((i) => i.can_apply_designs));
    });
  }, [resource, orderId]);

  const setLocationsForItem = (itemIndex: number, locationIds: OrderItem['order_design_ids']) => {
    setItems((prev) =>
      prev.map((item, idx) => {
        if (idx === itemIndex) {
          return { ...item, order_design_ids: locationIds };
        }
        return item;
      }),
    );
  };

  const toggleLocationSelection = (itemIndex: number) => {
    const item = items[itemIndex];
    if (item.order_design_ids[orderDesign.id]) {
      setLocationsForItem(itemIndex, omit(item.order_design_ids, orderDesign.id));
    } else {
      setLocationsForItem(itemIndex, {
        ...item.order_design_ids,
        [orderDesign.id]: { qty: null },
      });
    }
  };

  const onSelectAll = () => {
    setItems((prev) =>
      prev.map((i) => ({
        ...i,
        order_design_ids: { ...i.order_design_ids, [orderDesign.id]: { qty: null } },
      })),
    );
  };

  const onDeselectAll = () => {
    setItems((prev) =>
      prev.map((i) => ({
        ...i,
        order_design_ids: omit(i.order_design_ids, orderDesign.id),
      })),
    );
  };

  const onOrderDesignChange = (item: OrderItem, payload: OrderItemDesign) => {
    setItems((prev) =>
      prev.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            order_design_ids: {
              ...i.order_design_ids,
              [orderDesign.id]: {
                ...i.order_design_ids[orderDesign.id],
                ...payload,
              },
            },
          };
        }
        return i;
      }),
    );
  };

  const onQtyChange = (item: OrderItem, qty: number | null) => onOrderDesignChange(item, { qty });

  return (
    <ClosableDrawer
      title={`Choose Items For ${orderDesign.design.increment_id}`}
      open={open}
      onClose={onClose}
      width={750}
      closeOnClickOutside={false}
    >
      <div>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Each item selected will get <i>{orderDesign.design.name}</i> imprinted on{' '}
          <i>{orderDesign.design.location}</i>.
        </Typography>

        {itemsRequest.isLoading ? (
          <CircularProgress />
        ) : (
          <ItemDesignTable
            items={items}
            orderDesign={orderDesign}
            onToggle={toggleLocationSelection}
            onSelectAll={onSelectAll}
            onDeselectAll={onDeselectAll}
            onQtyChange={onQtyChange}
          />
        )}

        <DrawerButtons>
          <LoadingButton
            type="button"
            variant="contained"
            onClick={() => onSave(items)}
            loading={updateImprintsRequest.isLoading}
          >
            Save
          </LoadingButton>
        </DrawerButtons>
      </div>
    </ClosableDrawer>
  );
}
