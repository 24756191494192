import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient, Updater } from '@tanstack/react-query';
import axios from 'axios';
import {
  DesignLayout,
  DesignLayoutPayload,
  DesignLayoutWithProofs,
  DesignPayload,
  OrderDesign,
  OrderDesignCreatePayload,
  OrderDesignUpdatePayload,
  PartiallyRequired,
  Proof,
  ProofType,
  ArtRequest,
  Order,
  OrderItem,
  Design,
  OrderItemDesign,
} from '@/types';
import { makeResourceQueryKey, useRecord } from '@/utils/genericResource';

const RESOURCES = ['orders', 'quotes'] as const;

export function useSetDesignLayoutsForOrder(orderId: number) {
  const queryClient = useQueryClient();

  return useCallback(
    (data: Updater<DesignLayoutWithProofs[], DesignLayoutWithProofs[]>) => {
      RESOURCES.forEach((resource) => {
        const existingData = queryClient.getQueryData<DesignLayoutWithProofs[]>(
          makeResourceQueryKey(resource, orderId, 'designLayouts'),
        );

        if (!existingData) {
          return;
        }
        queryClient.setQueryData<DesignLayoutWithProofs[]>(
          makeResourceQueryKey(resource, orderId, 'designLayouts'),
          typeof data === 'function' ? data(existingData) : data,
        );
      });
    },
    [queryClient],
  );
}

export function useSetProofsForLayout(layout: DesignLayout, type: ProofType) {
  const setDesignLayoutsForOrder = useSetDesignLayoutsForOrder(layout.order_id);

  return useCallback(
    (data: Updater<Proof[], Proof[]>) => {
      setDesignLayoutsForOrder((prev) =>
        prev.map((l) => {
          if (l.id === layout.id) {
            return {
              ...l,
              [`${type}_proofs`]: typeof data === 'function' ? data(l[`${type}_proofs`]) : data,
            };
          }
          return l;
        }),
      );
    },
    [layout.id, setDesignLayoutsForOrder],
  );
}

export function useSetOrderDesigns(layoutId: number) {
  const record = useRecord<PartiallyRequired<ArtRequest, 'order'> | Order>();
  const orderId = 'order' in record ? record.order.id : record.id;
  const setDesignLayoutsForOrder = useSetDesignLayoutsForOrder(orderId);

  return useCallback(
    (data: Updater<OrderDesign[], OrderDesign[]>) => {
      setDesignLayoutsForOrder((prev) =>
        prev.map((l) => {
          if (l.id === layoutId) {
            return {
              ...l,
              order_designs: typeof data === 'function' ? data(l.order_designs) : data,
            };
          }
          return l;
        }),
      );
    },
    [layoutId, setDesignLayoutsForOrder],
  );
}

export function useGetDesignLayoutsForOrder(resource: 'orders' | 'quotes', orderId: number) {
  return useQuery(makeResourceQueryKey(resource, orderId, 'designLayouts'), () =>
    axios
      .get<{
        data: DesignLayoutWithProofs[];
      }>(
        `/api/design-layouts?with=order_designs,production_proofs,customer_proofs&order_id=${orderId}&count=100`,
      )
      .then(({ data }) => data.data),
  );
}

export function useGetDesignLayoutsById(ids: number[]) {
  const idString = ids.join();
  return useQuery(
    ['designLayoutsById', idString],
    () =>
      axios
        .get<{
          data: PartiallyRequired<DesignLayout, 'production_proofs'>[];
        }>(
          `/api/design-layouts?with=production_proofs&filter[id][in]=${idString}&count=${ids.length}`,
        )
        .then(({ data }) => data.data),
    {
      enabled: ids.length > 0,
    },
  );
}

export function useGetMatchingProofs(layoutId: number, enabled: boolean) {
  return useQuery(
    ['matchingProofs', layoutId],
    () =>
      axios
        .get<{ data: Proof[] }>(`/api/design-layouts/${layoutId}/matching-proofs`)
        .then(({ data }) => data.data),
    { enabled },
  );
}

export function useCreateDesignLayout(resource: 'orders' | 'quotes', orderId: number) {
  const setDesignLayouts = useSetDesignLayoutsForOrder(orderId);

  return useMutation((payload: DesignLayoutPayload) =>
    axios
      .post<DesignLayoutWithProofs>(`/api/${resource}/${orderId}/design-layouts`, payload)
      .then(({ data }) => {
        setDesignLayouts((prev) => [...prev, data]);
        return data;
      }),
  );
}

export function useUpdateDesignLayout(layout: DesignLayout) {
  const setDesignLayouts = useSetDesignLayoutsForOrder(layout.order_id);

  return useMutation((payload: DesignLayoutPayload) =>
    axios
      .put<DesignLayoutWithProofs>(
        `/api/design-layouts/${layout.id}?with=order_designs,production_proofs,customer_proofs`,
        payload,
      )
      .then(({ data }) => {
        setDesignLayouts((prev) => prev.map((l) => (l.id === data.id ? data : l)));
        return data;
      }),
  );
}

export function useDeleteDesignLayout(layout: DesignLayout) {
  const setDesignLayouts = useSetDesignLayoutsForOrder(layout.order_id);

  return useMutation(() =>
    axios.delete(`/api/design-layouts/${layout.id}`).then(() => {
      setDesignLayouts((prev) => prev.filter((l) => l.id !== layout.id));
    }),
  );
}

export function useCreateOrderDesign(layout: DesignLayout) {
  const setDesignLayouts = useSetDesignLayoutsForOrder(layout.order_id);

  return useMutation((payload: Omit<OrderDesignCreatePayload, 'design_layout_id'>) =>
    axios
      .post<OrderDesign>('/api/order-designs', { ...payload, design_layout_id: layout.id })
      .then(({ data }) => {
        setDesignLayouts((prev) =>
          prev.map((l) => {
            if (l.id === layout.id) {
              return {
                ...l,
                order_designs: [...l.order_designs, data],
              };
            }
            return l;
          }),
        );
        return data;
      }),
  );
}

export function useSortOrderDesigns(layout: DesignLayout) {
  const setDesignLayouts = useSetDesignLayoutsForOrder(layout.order_id);

  return useMutation((newOrderDesigns: OrderDesign[]) => {
    setDesignLayouts((prev) =>
      prev.map((l) => (l.id === layout.id ? { ...l, order_designs: newOrderDesigns } : l)),
    );
    return axios.put(`/api/design-layouts/${layout.id}/order-designs`, {
      ids: newOrderDesigns.map((od) => od.id),
    });
  });
}

export function useUpdateRequestedDesign(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation((payload: DesignPayload) =>
    axios
      .put<OrderDesign>(`/api/order-designs/${orderDesign.id}/design`, payload)
      .then(({ data }) => {
        setOrderDesigns((prev) => prev.map((od) => (od.id === data.id ? data : od)));
        return data;
      }),
  );
}

export function useUpdateDesign(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation((payload: DesignPayload) =>
    axios.put<Design>(`/api/designs/${orderDesign.design.id}`, payload).then(({ data }) => {
      setOrderDesigns((prev) =>
        prev.map((od) =>
          od.id === orderDesign.id
            ? {
                ...od,
                design: data,
              }
            : od,
        ),
      );
      return data;
    }),
  );
}

export function useUpdateOrderDesign(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation((payload: OrderDesignUpdatePayload) =>
    axios.put<OrderDesign>(`/api/order-designs/${orderDesign.id}`, payload).then(({ data }) => {
      setOrderDesigns((prev) => prev.map((od) => (od.id === data.id ? data : od)));

      return data;
    }),
  );
}

export function useUpdateOrderDesignImprints(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation((payload: Record<number, Partial<OrderItemDesign>>) =>
    axios
      .post<OrderDesign>(`/api/order-designs/${orderDesign.id}/items`, { items: payload })
      .then(({ data }) => {
        setOrderDesigns((prev) => prev.map((od) => (od.id === data.id ? data : od)));

        return data;
      }),
  );
}

export function useDeleteOrderDesign(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation(() =>
    axios.delete(`/api/order-designs/${orderDesign.id}`).then(() => {
      setOrderDesigns((prev) => prev.filter((od) => od.id !== orderDesign.id));
    }),
  );
}

export function usePurchaseOrderDesign(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation((dropsInHouse: boolean) =>
    axios
      .post<OrderDesign>(`/api/order-designs/${orderDesign.id}/purchase`, {
        drops_in_house: dropsInHouse,
      })
      .then(({ data }) => {
        setOrderDesigns((prev) => prev.map((od) => (od.id === data.id ? data : od)));
        return data;
      }),
  );
}

export function useUnpurchaseOrderDesign(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation(() =>
    axios.post<OrderDesign>(`/api/order-designs/${orderDesign.id}/undo`).then(({ data }) => {
      setOrderDesigns((prev) => prev.map((od) => (od.id === data.id ? data : od)));
      return data;
    }),
  );
}

export function useUncompleteDesign(orderDesign: OrderDesign) {
  const setOrderDesigns = useSetOrderDesigns(orderDesign.design_layout_id);

  return useMutation((artRequestId: number) =>
    axios
      .post<Design>(`/api/designs/${orderDesign.design.id}/uncomplete`, {
        uncompleted_via: artRequestId,
      })
      .then(({ data }) => {
        setOrderDesigns((prev) =>
          prev.map((od) => (od.id === orderDesign.id ? { ...od, design: data } : od)),
        );
        return data;
      }),
  );
}

export function useUploadRoster(layout: DesignLayout) {
  const queryClient = useQueryClient();

  return useMutation((file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios
      .post<{ data: OrderItem[] }>(`/api/design-layouts/${layout!.id}/roster`, formData)
      .then(({ data }) => {
        queryClient.invalidateQueries(
          makeResourceQueryKey('orders', layout.order_id, 'designLayouts'),
        );
        queryClient.invalidateQueries(
          makeResourceQueryKey('quotes', layout.order_id, 'designLayouts'),
        );
        return data.data;
      });
  });
}

export function useUpdateRoster(layout: DesignLayout) {
  const queryClient = useQueryClient();

  return useMutation((items: OrderItem[]) =>
    axios
      .put<{ data: OrderItem[] }>(`/api/design-layouts/${layout!.id}/roster`, { items })
      .then(({ data }) => {
        queryClient.invalidateQueries(
          makeResourceQueryKey('orders', layout.order_id, 'designLayouts'),
        );
        queryClient.invalidateQueries(
          makeResourceQueryKey('quotes', layout.order_id, 'designLayouts'),
        );
        return data.data;
      }),
  );
}

export function useUploadProof(layout: DesignLayout, type: ProofType) {
  const setProofs = useSetProofsForLayout(layout, type);

  return useMutation((file: File) => {
    const form = new FormData();
    form.append('file', file);
    return axios
      .post<Proof>(`/api/design-layouts/${layout.id}/${type}-proofs`, form)
      .then(({ data }) => {
        setProofs((prev) => [...prev, data]);
        return data;
      });
  });
}

export function useUpdateProof(layout: DesignLayout, type: ProofType) {
  const setProofs = useSetProofsForLayout(layout, type);

  return useMutation(({ id, ...payload }: { id: number; file_name: string }) =>
    axios
      .put<Proof>(`/api/design-layouts/${layout.id}/${type}-proofs/${id}`, payload)
      .then(({ data }) => {
        setProofs((prev) => prev.map((p) => (p.id === data.id ? data : p)));
        return data;
      }),
  );
}

export function useDeleteProof(layout: DesignLayout, type: ProofType) {
  const setProofs = useSetProofsForLayout(layout, type);

  return useMutation((proof: Proof) =>
    axios.delete(`/api/design-layouts/${layout.id}/${type}-proofs/${proof.id}`).then(() => {
      setProofs((prev) => prev.filter((p) => p.id !== proof.id));
    }),
  );
}

export function useCopyProof(layout: DesignLayout, type: ProofType) {
  const setProofs = useSetProofsForLayout(layout, type);

  return useMutation((proof: Proof) =>
    axios
      .post<Proof>(`/api/design-layouts/${layout.id}/${type}-proofs/${proof.id}/copy`)
      .then(({ data }) => {
        setProofs((prev) => [...prev, data]);
        return data;
      }),
  );
}
