import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import {
  Shipment,
  ShipmentCreatePayload,
  ShipmentItem,
  ShipmentQuotePayload,
  ShipmentWithDetails,
  ShippoRate,
} from '@/types';
import { makeResourceQueryKey, useOnReloadRecord } from '@/utils/genericResource';

function getQueryKey(shippableType: Shipment['shippable_type'], shippableId: number) {
  return makeResourceQueryKey(
    shippableType === 'order'
      ? 'orders'
      : shippableType === 'fulfillment_order'
        ? 'fulfillmentOrders'
        : 'inventoryReturns',
    shippableId,
    'shipments',
  );
}

export function useGetShipments(shippableType: Shipment['shippable_type'], shippableId: number) {
  return useQuery(getQueryKey(shippableType, shippableId), () =>
    axios
      .get<{ data: Shipment[] }>('/api/shipments', {
        params: {
          'filter[shippable_id]': String(shippableId),
          'filter[shippable_type]': shippableType,
          count: '10000',
        },
      })
      .then(({ data }) => data.data),
  );
}

export function useGetShipment(shipmentId: number | undefined) {
  return useQuery(
    ['shipment', shipmentId],
    () =>
      axios
        .get<ShipmentWithDetails>(`/api/shipments/${shipmentId}?with=items,kits`)
        .then(({ data }) => data),
    {
      enabled: shipmentId != null,
    },
  );
}

export function useCreateShipment() {
  const queryClient = useQueryClient();
  const reloadRecord = useOnReloadRecord();

  return useMutation((payload: ShipmentCreatePayload) =>
    axios.post<Shipment>('/api/shipments', payload).then(({ data }) => {
      queryClient.setQueryData<Shipment[]>(
        getQueryKey(data.shippable_type, data.shippable_id),
        (prev) => {
          if (!prev) return prev;
          return [...prev, data];
        },
      );
      queryClient.invalidateQueries(makeResourceQueryKey('orders', data.shippable_id, 'addresses'));
      queryClient.invalidateQueries(['shippableItems']);
      reloadRecord();
      return data;
    }),
  );
}

export function useUpdateShipment(shipment: Shipment) {
  const queryClient = useQueryClient();

  return useMutation((payload: { cost: number }) =>
    axios
      .put<ShipmentWithDetails>(`/api/shipments/${shipment.id}?with=items,kits`, payload)
      .then(({ data }) => {
        queryClient.setQueryData(['shipment', shipment.id], data);
        queryClient.setQueryData<Shipment[]>(
          getQueryKey(shipment.shippable_type, shipment.shippable_id),
          (prev) => {
            if (!prev) return prev;
            return prev.map((s) => (s.id === data.id ? data : s));
          },
        );
        return data;
      }),
  );
}

export function useDeleteShipment() {
  const queryClient = useQueryClient();
  const reloadRecord = useOnReloadRecord();

  return useMutation((shipment: Shipment) =>
    axios.delete(`/api/shipments/${shipment.id}`).then(() => {
      queryClient.setQueryData<Shipment[]>(
        getQueryKey(shipment.shippable_type, shipment.shippable_id),
        (prev) => {
          if (!prev) return prev;
          return prev.filter((s) => s.id !== shipment.id);
        },
      );
      queryClient.invalidateQueries(
        makeResourceQueryKey('orders', shipment.shippable_id, 'addresses'),
      );
      queryClient.invalidateQueries(['shippableItems']);
      reloadRecord();
    }),
  );
}

export function useUpdateShipmentItem(shipment: Shipment) {
  const queryClient = useQueryClient();

  return useMutation(({ itemId, ...payload }: { itemId: number; qty_shipped: number }) =>
    axios
      .put<ShipmentItem>(`/api/shipments/${shipment.id}/items/${itemId}`, payload)
      .then(({ data }) => {
        queryClient.invalidateQueries(['shipment']);
        queryClient.invalidateQueries(getQueryKey(shipment.shippable_type, shipment.shippable_id));
        queryClient.invalidateQueries(['shippableItems']);
        return data;
      }),
  );
}

export function useRemoveItemFromShipment(shipment: Shipment) {
  const queryClient = useQueryClient();

  return useMutation((itemId: number) =>
    axios.delete(`/api/shipments/${shipment.id}/items/${itemId}`).then(() => {
      queryClient.invalidateQueries(['shipment']);
      queryClient.invalidateQueries(getQueryKey(shipment.shippable_type, shipment.shippable_id));
      queryClient.invalidateQueries(['shippableItems']);
    }),
  );
}

export function useGetShipmentQuote(
  values: ShipmentQuotePayload,
  onError: (e: AxiosError) => void,
) {
  return useQuery(
    ['rates', JSON.stringify(values)],
    () =>
      axios
        .post<{ rates: ShippoRate[] }>('/api/shipments/quote', values)
        .then(({ data }) => data.rates),
    {
      retry: false,
      onError,
    },
  );
}
