import { CircularProgress, Stack } from '@mui/material';
import { useGetAddressesForOrder } from '@/api/addresses';
import OrderShippingKit from '@/components/Shipping/OrderShippingKit';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import { useRecord } from '@/utils/genericResource';

export default function PackoutShippingStatus() {
  const { order } = useRecord('orderPackouts', true);
  const { data: addresses, isLoading, refetch } = useGetAddressesForOrder('orders', order.id);

  return (
    <Stack spacing={2}>
      {isLoading ? (
        <CircularProgress />
      ) : addresses ? (
        <OrderShippingKit
          orderId={order.id}
          customerId={order.customer.id}
          addresses={addresses}
          refetch={refetch}
        />
      ) : null}

      <ShipmentsCard shippableId={order.id} shippableType="order" />
    </Stack>
  );
}
