import { Block, Check, FileCopy, Link, OpenInNew, Publish, Send, Undo } from '@mui/icons-material';
import axios from 'axios';
import { z } from 'zod';
import {
  Action,
  AnchorAction,
  ButtonAction,
  CustomAction,
  FieldFactory,
  LinkAction,
  Resource,
  StackLayout,
  Tab,
  TabLayout,
} from '@/classes';
import Analysis from '@/components/Orders/Analysis/Analysis';
import OrderArt from '@/components/Orders/Art/OrderArt';
import { QuoteCreateWizard } from '@/components/Orders/Create/OrderCreateWizard';
import OrderItemsField from '@/components/Orders/Items/OrderItemsField';
import PrintMenu from '@/components/Print/PrintMenu';
import QuoteDashboard from '@/components/Quotes/QuoteDashboard';
import StatusChip from '@/components/Shared/StatusChip';
import { QUOTE_STATUS_COLORS } from '@/constants';
import { getEventableConfigForResource } from '@/helpers';
import { Quote, orderUpdatePayloadSchema } from '@/types';
import { getOrderColumns } from './orders';

const QuoteActions = (values: Quote) => {
  const actions: Action[] = [
    new CustomAction('Status', () => (
      <StatusChip status={values.status} colors={QUOTE_STATUS_COLORS} />
    )),
    new CustomAction('Print', () => <PrintMenu model="quote" id={values.id} />),
    new AnchorAction('View in Portal', values.portal_url, true).withIcon(OpenInNew),
    new ButtonAction('Email to Customer', ({ dialogs }) => {
      dialogs.prompt({
        title: 'Email Quote to Customer',
        description:
          'This will send an email to the customer with the PDF form of the quote attached. You will be copied on the email.',
        initialValues: {
          email: values.contact_email,
          content: `Hi ${values.contact_first_name},\n\nThe quote you requested is attached.`,
        },
        schema: z.object({
          email: z.string().email(),
          content: z.string(),
        }),
        fields: [
          FieldFactory.email('email').withLabel('To Email'),
          FieldFactory.textarea('content').withLabel('Message Body'),
        ],
        onSubmit: (v) => axios.post(`/api/quotes/${values.id}/send`, v),
      });
    }).withIcon(Send),
    new ButtonAction(
      'Duplicate',
      ({ dialogs, setIsLoading, navigate }) => {
        dialogs
          .confirm({
            title: 'Duplicate Quote',
            description: 'Are you sure you want to duplicate this quote?',
          })
          .then(() => {
            setIsLoading(true);
            axios
              .post<Quote>(`/api/quotes/${values.id}/duplicate`)
              .then(({ data }) => {
                navigate(`/quotes/${data.id}`);
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
      },
      FileCopy,
    ),
  ];

  if (values.status === 'open') {
    actions.push(
      new ButtonAction('Close & Mark Declined', ({ dialogs, onReloadRecord }) => {
        dialogs.confirm({ title: 'Mark Quote Declined', description: 'Are you sure?' }).then(() => {
          axios.post(`/api/quotes/${values.id}/decline`).then(() => {
            onReloadRecord();
          });
        });
      }).withIcon(Block),
      new ButtonAction('Close & Mark Accepted', ({ dialogs, onReloadRecord }) => {
        dialogs.confirm({ title: 'Mark Quote Accepted', description: 'Are you sure?' }).then(() => {
          axios.post(`/api/quotes/${values.id}/accept`).then(() => {
            onReloadRecord();
          });
        });
      }).withIcon(Check),
      new ButtonAction('Close & Convert to Order', ({ dialogs, toast, navigate, setIsLoading }) => {
        dialogs
          .confirm({ title: 'Convert Quote to Order', description: 'Are you sure?' })
          .then(() => {
            setIsLoading(true);
            axios
              .post(`/api/quotes/${values.id}/convert`)
              .then(({ data }) => {
                navigate(`/orders/${data.id}`);
                toast(`${values.increment_id} has been converted to ${data.increment_id}`);
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
      }).withIcon(Publish),
    );
  } else {
    actions.push(
      new ButtonAction('Re-Open Quote', ({ onReloadRecord }) => {
        axios.post(`/api/quotes/${values.id}/open`).then(() => {
          onReloadRecord();
        });
      }).withIcon(Undo),
    );
  }

  if (values.converted_order_id) {
    actions.push(
      new LinkAction('Go To Order', `/orders/${values.converted_order_id}`).withIcon(Link),
    );
  }

  return actions;
};

export default function quotes() {
  return new Resource<Quote>('Quotes')
    .withSchema(orderUpdatePayloadSchema)
    .getTitleUsing((o) => o.label)
    .getLabelUsing((o) => o.label)
    .withDefaultSort('-increment_id')
    .createUsing(QuoteCreateWizard)
    .getSingleActionsUsing(QuoteActions)
    .withDefaultFilters({
      status: 'open',
    })
    .withInitialColumns([
      'increment_id',
      'placed_at',
      'customer',
      'sales_rep',
      'order_type',
      'status',
      'total',
    ])
    .withQueryParams({
      single: {
        with: 'billing_address',
      },
    })
    .withColumns(
      getOrderColumns().filter(
        (c) =>
          ![
            'invoiced_at',
            'paid',
            'balance',
            'status',
            'terms',
            'due_date',
            'earliest_ship_date',
            'drop_dead_date',
            'committed_ship_date',
            'shipping_mode',
            'cached_revenue',
            'cached_costs',
            'gross_profit',
            'margin',
          ].includes(c.name),
      ),
    )
    .addColumns([FieldFactory.status('status', QUOTE_STATUS_COLORS).sortable().quickFilterable()])
    .withFields([
      new TabLayout('tab', [
        new Tab('quote', [
          new StackLayout('stack', [
            FieldFactory.custom('_dashboard', QuoteDashboard),
            FieldFactory.custom('_items', OrderItemsField),
            getEventableConfigForResource('quotes'),
          ]),
        ]),
        new Tab('art', [FieldFactory.custom('design_layouts', OrderArt)]).shouldRenderUsing(
          (q: Quote) => !q.order_type.is_blank,
        ),
        new Tab('analysis', [FieldFactory.custom('analysis', Analysis)]),
      ]),
    ]);
}
