import { Dialog } from '@mui/material';
import { Issue } from '@/types';
import IssueDetails from './IssueDetails';

export default function IssueDialog({
  issue,
  open,
  onClose,
}: {
  issue?: Issue;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <div style={{ width: 1000, minHeight: 700 }}>
        {issue && <IssueDetails issue={issue} size="small" />}
      </div>
    </Dialog>
  );
}
