import { Card, CardContent, CardHeader, List } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import axios from 'axios';
import { Moment } from 'moment/moment';
import { useKitProductionOrders } from '@/api/packout';
import OrderListItem from '@/components/Orders/OrderListItem';
import { getDateString, getMoment } from '@/utils/dates';
import { useRecord, useUpdateRecord } from '@/utils/genericResource';
import { useResource } from '@/utils/hooks/useGetResource';
import usePushWithContext from '@/utils/hooks/usePushWithContext';

export default function PackoutPendingStatus() {
  const { id, order, scheduledDate } = useRecord('orderPackouts', true);
  const updateRecord = useUpdateRecord('orderPackouts');
  const orderResource = useResource('orders');

  const { data: otherOrders = [] } = useKitProductionOrders(order.id);

  const push = usePushWithContext(
    otherOrders.map((o) => ({
      key: o.id,
      label: orderResource.getTitle(o),
      href: `/orders/${o.id}`,
    })),
  );

  const onSchedule = (date: Moment | null) => {
    axios.put(`/api/order-packouts/${id}`, { scheduled_date: getDateString(date) });
    updateRecord({ scheduled_date: getDateString(date) });
  };

  return (
    <Card>
      <CardHeader title="Scheduling" />
      <CardContent>
        <DatePicker label="Date" value={getMoment(scheduledDate)} onChange={onSchedule} />
      </CardContent>
      {otherOrders.length > 0 && (
        <>
          <CardHeader subheader="Dependent Orders" />
          <List>
            {otherOrders.map((o) => (
              <OrderListItem key={o.id} order={o} push={push} />
            ))}
          </List>
        </>
      )}
    </Card>
  );
}
