import { Grid2 as Grid, Hidden, Box, Card, CardContent } from '@mui/material';
import IssuesCard from '@/components/Issues/IssuesCard';
import EventableCard from '@/components/Notes/EventableCard';
import OrderStatus from '@/components/Orders/OrderStatus';
import ProjectCard from '@/components/Projects/ProjectCard';
import PurchaseOrderStatuses from '@/components/Purchasing/PurchaseOrderStatuses';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import OrderProvider from '@/contexts/OrderContext';
import { OrderWithDetails } from '@/types';
import { useRecord } from '@/utils/genericResource';
import OrderApprovals from './OrderApprovals';
import OrderArtRequests from './OrderArtRequests';
import OrderDesignTiles from './OrderDesignTiles';
import OrderDetails from './OrderDetails';
import OrderPackouts from './OrderPackouts';
import OrderProductionApproval from './OrderProductionApproval';
import OrderStatuses from './OrderStatuses';
import OrderTiming from './OrderTiming';
import RelatedOrders from './RelatedOrders';

export default function OrderDashboard() {
  const order = useRecord<OrderWithDetails>();
  const orderId = order.id;
  const isReadOnly = Boolean(order.invoiced_at || order.cancelled_at);

  return (
    <OrderProvider order={order}>
      <Grid container spacing={2}>
        {order.status === 'Pending Production Approval' && (
          <Grid size={12}>
            <OrderProductionApproval orderId={orderId} />
          </Grid>
        )}
        <Grid size={{ xs: 12, md: 8 }}>
          <div>
            <Grid container spacing={3}>
              <Grid size={12}>
                <Box mb={2}>
                  <Card>
                    <Grid container>
                      <Grid size={{ xs: 12, md: 7 }}>
                        <OrderDetails order={order} isReadOnly={isReadOnly} />
                      </Grid>
                      <Hidden lgDown>
                        <Grid size={{ xs: 12, md: 5 }}>
                          <OrderDesignTiles orderId={order.id} />
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Card>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                {order.approvals && <OrderApprovals approvals={order.approvals} />}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                {order.art_requests && <OrderArtRequests artRequests={order.art_requests} />}
              </Grid>
              <Grid size={12}>
                <EventableCard resourceId={order.id} resource="orders" />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <OrderStatus status={order.status} withDetails />
            </CardContent>
            <OrderStatuses orderId={orderId} disabled={isReadOnly} />
          </Card>

          {order.shipping_mode === 'kits' && (
            <Box mb={2}>
              <OrderPackouts orderId={orderId} invoicedAt={order.invoiced_at} />
            </Box>
          )}

          {order.project_id && <ProjectCard projectId={order.project_id} />}

          {!order.order_type.is_webstore && <OrderTiming orderId={orderId} disabled={isReadOnly} />}

          <CardWithGutter>
            {order.owned_purchase_orders.map((po) => (
              <PurchaseOrderStatuses key={po.id} purchaseOrder={po} />
            ))}
          </CardWithGutter>

          <IssuesCard issuableType="order" issuableId={orderId} />

          <RelatedOrders orderId={orderId} />
        </Grid>
      </Grid>
    </OrderProvider>
  );
}
