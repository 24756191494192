import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Receiving, Order, PurchaseOrder, ReceivingPayload } from '@/types';
import { makeResourceQueryKey, useOnReloadRecord, useRecord } from '@/utils/genericResource';

function useReceivingContext() {
  const record = useRecord<Order | PurchaseOrder>();
  const resource = 'issued_date' in record ? 'purchaseOrders' : 'orders';
  const foreignKey = 'issued_date' in record ? 'purchase_order_id' : 'order_id';
  const recordId = record.id;

  return { resource, foreignKey, recordId } as const;
}

export function useGetReceivings() {
  const { resource, foreignKey, recordId } = useReceivingContext();

  return useQuery(makeResourceQueryKey(resource, recordId, 'receivings'), () =>
    axios
      .get<{ data: Receiving[] }>('/api/receivings', {
        params: {
          [foreignKey]: recordId,
        },
      })
      .then(({ data }) => data.data),
  );
}

export function useCreateReceiving() {
  const { resource, foreignKey, recordId } = useReceivingContext();
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation((payload: ReceivingPayload) =>
    axios
      .post<Receiving>(`/api/receivings`, {
        ...payload,
        [foreignKey]: recordId,
      })
      .then(({ data }) => {
        queryClient.setQueryData<Receiving[]>(
          makeResourceQueryKey(resource, recordId, 'receivings'),
          (prev) => {
            if (!prev) {
              return prev;
            }
            return [...prev, data];
          },
        );
        // Reload the PO items to get latest qty_received
        onReload();
        return data;
      }),
  );
}

export function useUpdateReceiving() {
  const { resource, recordId } = useReceivingContext();
  const queryClient = useQueryClient();

  return useMutation(({ id, ...payload }: Partial<ReceivingPayload> & { id: number }) =>
    axios.put<Receiving>(`/api/receivings/${id}`, payload).then(({ data }) => {
      queryClient.setQueryData<Receiving[]>(
        makeResourceQueryKey(resource, recordId, 'receivings'),
        (prev) => {
          if (!prev) {
            return prev;
          }
          return prev.map((r) => (r.id === data.id ? data : r));
        },
      );
      return data;
    }),
  );
}

export function useDeleteReceiving() {
  const { resource, recordId } = useReceivingContext();
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation((receivingId: number) =>
    axios.delete(`/api/receivings/${receivingId}`).then(() => {
      queryClient.setQueryData<Receiving[]>(
        makeResourceQueryKey(resource, recordId, 'receivings'),
        (prev) => {
          if (!prev) {
            return prev;
          }
          return prev.filter((r) => r.id !== receivingId);
        },
      );
      // Reload the PO items to get latest qty_received
      onReload();
    }),
  );
}

export function useDeleteReceivingItem() {
  const { resource, recordId } = useReceivingContext();
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation(({ receivingId, itemId }: { receivingId: number; itemId: number }) => {
    return axios.delete(`/api/receivings/${receivingId}/items/${itemId}`).then(() => {
      queryClient.setQueryData<Receiving[]>(
        makeResourceQueryKey(resource, recordId, 'receivings'),
        (prev) => {
          if (!prev) {
            return prev;
          }
          return prev.map((r) => ({
            ...r,
            items: r.items?.filter((i) => i.id !== itemId),
          }));
        },
      );
      // Reload the PO items to get latest qty_received
      onReload();
    });
  });
}

export function useUnreceivePoItem() {
  const { resource, recordId } = useReceivingContext();
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation((poItemId: number) =>
    axios.post(`/api/purchase-order-items/${poItemId}/unreceive`).then(() => {
      queryClient.invalidateQueries<Receiving[]>(
        makeResourceQueryKey(resource, recordId, 'receivings'),
      );
      onReload();
    }),
  );
}
