import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { OrderItem, OrderItemPayload, PartiallyRequired } from '@/types';

type OrderItemWithInventoryVariant = PartiallyRequired<OrderItem, 'inventory_variant'>;

export function useGetShippableItems(orderId: number) {
  return useQuery(['shippableItems', orderId], () =>
    axios
      .get<{
        data: OrderItemWithInventoryVariant[];
      }>(`/api/orders/${orderId}/items?filter[is_shippable]=1&with=inventory_variant.product`)
      .then(({ data }) => data.data),
  );
}

export function useUpdateShippableOrderItem(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation(({ id, ...payload }: Partial<OrderItemPayload> & { id: number }) =>
    axios
      .put<OrderItemWithInventoryVariant>(
        `/api/orders/${orderId}/items/${id}?with=inventory_variant`,
        payload,
      )
      .then(({ data }) => {
        queryClient.setQueryData<OrderItemWithInventoryVariant[]>(
          ['shippableItems', orderId],
          (prev) => {
            if (!prev) return prev;
            return prev.map((item) => (item.id === data.id ? data : item));
          },
        );
        return data;
      }),
  );
}
