import { Box, Grid2 as Grid, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Paper from '@/components/Shared/Paper';
import { OrderItemToPurchase } from '@/types';
import { makeResourceQueryKey, useRecord } from '@/utils/genericResource';
import IssuesCard from '../Issues/IssuesCard';
import NoteManagerCard from '../Notes/NoteManagerCard';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import PurchasingForOrder from './PurchasingForOrder';

export default function PurchasingScreen() {
  const order = useRecord('ordersToPurchase');

  const { data: items, isLoading } = useQuery(
    makeResourceQueryKey('ordersToPurchase', order.id, 'itemsToPurchase'),
    () =>
      axios
        .get<{ data: OrderItemToPurchase[] }>(`/api/orders/${order.id}/items-to-purchase`)
        .then(({ data }) => data.data),
  );

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 9 }}>
        {items && <PurchasingForOrder orderId={order.id} items={items} />}
        {isLoading && (
          <Paper>
            <Box display="flex" mb={3}>
              <Box flexGrow={1} mr={2}>
                <Typography variant="h5">
                  <Skeleton variant="text" />
                </Typography>
              </Box>
              <Box display="flex">
                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
            </Box>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Paper>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <OrderSummaryCard order={order} withAddresses />

        <IssuesCard issuableType="order" issuableId={order.id} />

        <NoteManagerCard resource="orders" resourceId={order.id} size="small" />
      </Grid>
    </Grid>
  );
}
