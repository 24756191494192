import { useMemo, useState } from 'react';
import { Delete, Description, Edit, Image, Print } from '@mui/icons-material';
import { Card, CardHeader, Chip, IconButton, Tooltip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import { useDeleteShipment, useGetShipments } from '@/api/shipping';
import AddressBlock from '@/components/Addresses/AddressBlock';
import Can from '@/components/Permissions/Can';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import UserLabel from '@/components/Users/UserLabel';
import { Shipment } from '@/types';
import curr from '@/utils/curr';
import getApiUrl from '@/utils/getApiUrl';
import useDialogs from '@/utils/hooks/useDialogs';
import ShipmentDetailsDrawer from './ShipmentDetailsDrawer';

export default function ShipmentsCard({
  shippableId,
  shippableType,
  showActions = true,
}: {
  shippableId: number;
  shippableType: 'order' | 'fulfillment_order' | 'return';
  showActions?: boolean;
}) {
  const [viewing, setViewing] = useState<Shipment>();
  const { confirm } = useDialogs();

  const { data: shipments = [], isFetching, refetch } = useGetShipments(shippableType, shippableId);
  const deleteRequest = useDeleteShipment();

  const onDeleteShipment = (shipment: Shipment) => {
    let description = 'Are you sure you want to delete this shipment?';
    if (shipment.external_source === 'shippo') {
      description += ' This will refund the label in Shippo and make it unusable.';
    }

    confirm({ title: 'Delete Shipment', description, color: 'error' }).then(() => {
      deleteRequest.mutate(shipment);
    });
  };

  const columns = useMemo(() => {
    const columns: ColumnDef<Shipment>[] = [
      {
        header: 'Carrier',
        id: 'carrier',
        accessorFn: (s) => s.carrier_name || `Shipment #${s.id}`,
      },
      {
        header: 'Tracking',
        accessorKey: 'tracking_number',
        cell: ({ row, getValue }) => (
          <>
            {row.original.tracking_url ? (
              <a href={row.original.tracking_url} target="_blank" rel="noreferrer">
                {getValue<string>()}
              </a>
            ) : (
              getValue<string>()
            )}
          </>
        ),
      },
      {
        header: 'Recipient',
        accessorKey: 'address.name',
        cell: ({ getValue, row }) =>
          row.original.address && (
            <Tooltip title={<AddressBlock address={row.original.address} />}>
              <div>{getValue<string>()}</div>
            </Tooltip>
          ),
      },
      {
        header: 'Cost',
        accessorKey: 'cost',
        cell: ({ getValue, row }) => (
          <>
            {curr(getValue<number>())}
            {row.original.billing_type === 'THIRD_PARTY' && (
              <Chip sx={{ ml: 1 }} label="3rd Party" size="small" />
            )}
          </>
        ),
      },
      {
        header: 'Date',
        accessorKey: 'created_at',
        cell: ({ getValue }) => moment(getValue<string>()).format('lll'),
      },
    ];

    if (showActions) {
      columns.push(
        {
          header: 'Shipped By',
          accessorKey: 'shipped_by.name',
          cell: ({ row }) => <UserLabel user={row.original.shipped_by} />,
        },
        {
          id: 'actions',
          header: '',
          cell: ({ row: { original: s } }) => (
            <Can permission="write:shipments">
              <IconButton onClick={() => setViewing(s)} size="small">
                <Edit fontSize="small" />
              </IconButton>
              {s.file ? (
                <Tooltip title="View Uploaded File">
                  <IconButton component="a" target="_blank" href={s.file} size="small">
                    <Image fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
              {s.label_url && moment(s.created_at).diff(moment(), 'months') < 6 ? (
                <Tooltip title="View Label">
                  <IconButton
                    component="a"
                    target="_blank"
                    href={getApiUrl(`/api/shipments/${s.id}/label`)}
                    size="small"
                  >
                    <Print fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
              {s.commercial_invoice_url && moment(s.created_at).diff(moment(), 'months') < 6 ? (
                <Tooltip title="View Commercial Invoice">
                  <IconButton
                    component="a"
                    target="_blank"
                    href={s.commercial_invoice_url}
                    size="small"
                  >
                    <Description fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null}
              <IconButton onClick={() => onDeleteShipment(s)} size="small">
                <Delete fontSize="small" />
              </IconButton>
            </Can>
          ),
        },
      );
    }
    return columns;
  }, [showActions]);

  return (
    <Card>
      <CardHeader title="Shipments for Order" />

      <PaginatedTable
        rows={shipments}
        columns={columns}
        onReload={refetch}
        isFetching={isFetching || deleteRequest.isLoading}
        searchable={['tracking_number', 'address.name']}
        enableToolbar={shipments.length > 3}
      />

      {showActions && (
        <ShipmentDetailsDrawer onClose={() => setViewing(undefined)} shipment={viewing} />
      )}
    </Card>
  );
}
