import { Box } from '@mui/material';
import moment from 'moment';
import WarningIcon from '@/components/Shared/WarningIcon';
import { Order } from '@/types';

export default function ProductionDates({ order }: { order: Order }) {
  const committed = moment(order.committed_ship_date).format('L');

  if (order.drop_dead_date) {
    return (
      <Box display="inline-flex" alignItems="center" gap={0.5}>
        <span>{committed} </span>
        <WarningIcon
          message={`Drop Dead Date: ${moment(order.drop_dead_date).format('L')}`}
          style={{ fontSize: '1.2rem', verticalAlign: 'baseline' }}
        />
      </Box>
    );
  }

  return <span>{committed}</span>;
}
