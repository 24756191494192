import { Fragment } from 'react';
import { ArtTrack } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid2 as Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { useCreateDesignLayout, useGetDesignLayoutsForOrder } from '@/api/designs';
import { FieldFactory } from '@/classes';
import { FieldProps } from '@/classes/types';
import DesignLayoutCard from '@/components/Designs/DesignLayoutCard';
import OrderDesignsList from '@/components/Designs/OrderDesignsList';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import Paper from '@/components/Shared/Paper';
import { randomElement } from '@/helpers';
import {
  DesignLayout,
  designLayoutPayloadSchema,
  genericModelReferenceSchema,
  Order,
} from '@/types';
import { useRecord, useRecordId } from '@/utils/genericResource';
import useApiSegment from '@/utils/hooks/useApiSegment';
import useDialogs from '@/utils/hooks/useDialogs';

function ExistingLayoutField({ fieldModel, ...props }: FieldProps) {
  const allCustomers = useFormContext<{ all_customers?: boolean }>().watch('all_customers');
  const customerId = useRecord<Order>().customer.id;
  const requestParams: Record<string, string> = {};

  if (!allCustomers) {
    requestParams.customer_id = String(customerId);
  }

  const newField = FieldFactory.belongsTo(fieldModel.name, 'designLayouts').withRequestParams(
    requestParams,
  );

  return newField.renderEditComponent(props);
}

export default function OrderArt() {
  const {
    order_type: { is_blank: isBlank, is_webstore: isWebstore },
    invoiced_at: invoicedAt,
    released_at: releasedAt,
    cancelled_at: cancelledAt,
  } = useRecord<Order>();

  const resource = useApiSegment(1) as 'orders' | 'quotes';
  const recordId = Number(useRecordId());

  const { prompt } = useDialogs();

  const { data: designLayouts, isLoading } = useGetDesignLayoutsForOrder(resource, recordId);
  const createDesignLayoutRequest = useCreateDesignLayout(resource, recordId);

  const canEdit = (layout?: DesignLayout): boolean => {
    if (invoicedAt || releasedAt || cancelledAt) {
      return false;
    }
    if (layout && layout.subcontract_po_id) {
      return false;
    }
    return true;
  };

  const createNew = () => {
    prompt({
      title: 'Create New Design Layout',
      fields: [
        FieldFactory.text('name')
          .withSize('medium')
          .withHelp(
            randomElement([
              'e.g. 2021 Basketball Shooting Shirts',
              'e.g. 2020 Holiday Shirts',
              'e.g. Reversible Practice Jerseys',
              'e.g. Turkey Trot Shirts',
            ]),
          ),
      ],
      schema: designLayoutPayloadSchema,
      onSubmit: (v) => createDesignLayoutRequest.mutateAsync(v),
    });
  };

  const addExisting = () => {
    prompt({
      title: 'Start From Existing Layout',
      fields: [
        FieldFactory.custom('design_layout', ExistingLayoutField),
        FieldFactory.boolean('all_customers', 'Include designs from other customers'),
      ],
      schema: z.object({
        design_layout: genericModelReferenceSchema,
        all_customers: z.boolean().nullish(),
      }),
      onSubmit: (v) =>
        createDesignLayoutRequest.mutateAsync({
          design_layout_id: v.design_layout.id,
        }),
    });
  };

  const actions = (
    <Box py={1}>
      <Button variant="contained" onClick={addExisting} sx={{ mr: 1 }}>
        Start From Existing
      </Button>
      <Button onClick={createNew}>Create New Design Layout</Button>
    </Box>
  );

  if (!designLayouts) {
    return <CircularProgress />;
  }

  if (isBlank) {
    return (
      <Paper>
        <Typography>This order type does not allow for decoration.</Typography>
      </Paper>
    );
  }

  return (
    <div>
      {designLayouts.length === 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box textAlign="center" py={2} maxWidth="sm">
            <ArtTrack sx={{ color: 'grey.400', fontSize: 100 }} />
            <Typography variant="h6">Get Started with Art</Typography>
            <Typography color="textSecondary" sx={{ mb: 3 }}>
              Create layouts for your order consisting of new or existing designs. Each layout will
              be proofed separately.
            </Typography>
            {canEdit() && actions}
          </Box>
        </Box>
      ) : (
        <div>
          {canEdit() && actions}

          <Grid container spacing={2}>
            {designLayouts.map((layout) => (
              <Fragment key={layout.id}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <DesignLayoutCard
                    layout={layout}
                    isWebstore={isWebstore}
                    invoicedAt={invoicedAt}
                    canEdit={canEdit(layout)}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                  <OrderDesignsList layout={layout} canEdit={canEdit(layout)} />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </div>
      )}

      <LoadingBackdrop loading={isLoading} />
    </div>
  );
}
