import { useState } from 'react';
import { Chip, CircularProgress, Popover } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import KitBlock from '@/components/Kitting/KitBlock';
import { Kit } from '@/types';

export default function KitLabel({ kit }: { kit: Kit }) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { data } = useQuery(
    ['kit', kit.id],
    () => axios.get<Required<Kit>>(`/api/kits/${kit.id}`).then(({ data }) => data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(anchorEl),
    },
  );

  return (
    <span style={{ marginRight: 4 }}>
      <button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        type="button"
        style={{
          padding: 0,
          border: 'none',
          background: 'none',
          cursor: 'pointer',
        }}
      >
        <Chip label={kit.bin_string} variant="outlined" />
      </button>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {data ? (
          <KitBlock kit={data} />
        ) : (
          <div
            style={{
              width: 300,
              height: 300,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Popover>
    </span>
  );
}
