import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Kit, KitItem, Order, AddressWithKits } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';
import useToast from '@/utils/hooks/useToast';

export function useGetKitItemsForPackout(orderId: number) {
  return useQuery(['packoutKitItems', orderId], () =>
    axios
      .get<{ data: Required<KitItem>[] }>(`/api/kit-items?order_id=${orderId}`)
      .then(({ data }) => data.data),
  );
}

export function useWatchKitItems(orderId: number) {
  const queryClient = useQueryClient();

  useEffect(() => {
    const channel = window.Echo.private(`packouts.${orderId}`);

    channel.listen('KitItemPicked', (e: { kit_item: KitItem }) => {
      queryClient.setQueryData<Required<KitItem>[]>(['packoutKitItems', orderId], (prev) => {
        if (!prev) return prev;
        return prev.map((i) => (i.id === e.kit_item.id ? { ...i, ...e.kit_item } : i));
      });
    });

    return () => {
      channel.stopListening('KitItemPicked');
      window.Echo.leave(`packouts.${orderId}`);
    };
  }, [orderId, queryClient]);
}

export function usePickKitItem(orderId: number) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, ...other }: { id: number; picked_qty: number }) =>
      axios.post<Required<KitItem>>(`/api/kit-items/${id}/pick`, other).then(({ data }) => data),
    {
      onSuccess: (item) => {
        queryClient.setQueryData<Required<KitItem>[]>(['packoutKitItems', orderId], (oldData) => {
          if (!oldData) return [];
          return oldData.map((i) => (i.id === item.id ? item : i));
        });
      },
    },
  );
}

export function useGetKitsForOrder(orderId: number) {
  return useQuery(['kitsForOrder', orderId], () =>
    axios.get<{ data: Kit[] }>(`/api/kits?order_id=${orderId}`).then(({ data }) => data.data),
  );
}

export function usePerformKitAction(orderId: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(({ id, action }: { id: number; action: string }) =>
    axios.post<Kit>(`/api/kits/${id}/${action}`).then(({ data }) => {
      queryClient.setQueryData<Kit[]>(['kitsForOrder', orderId], (prev) => {
        if (!prev) return prev;
        return prev.map((p) => {
          if (p.id === data.id) {
            return data;
          }
          return p;
        });
      });

      queryClient.setQueryData<AddressWithKits[]>(
        makeResourceQueryKey('orders', orderId, 'addresses'),
        (prev) => {
          if (!prev) return prev;
          return prev.map((p) => ({
            ...p,
            kits: p.kits?.map((k) => {
              if (k.id === data.id) {
                return data;
              }
              return k;
            }),
          }));
        },
      );
      toast(`Kit: ${data.number || data.name} is now ${data.pick_status}`);
    }),
  );
}

export function useKitProductionOrders(orderId: number) {
  return useQuery(['kitProductionOrders', orderId], () =>
    axios
      .get<{ data: Order[] }>(`/api/orders?in_kit=${orderId}&count=25`)
      .then(({ data }) => data.data),
  );
}
