import { createContext, useContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { Field } from '@/classes';

export interface DataTableState {
  selected: (number | string)[];
  query: string;
  filters: FieldValues;
  columns: string[];
  page: number;
  count: number;
  sort: string | null;
  aggregations: {
    sum: string[];
    avg: string[];
  };
}

interface DataTableActions {
  onSortChange: (c: string) => void;
  onPageChange: (p: number) => void;
  onCountChange: (p: number) => void;
  onColumnsChange: (cols: string[]) => void;
  onSelected: (v: (string | number)[]) => void;
  onQuery: (q: string) => void;
  onFilter: (k: string, v: any) => void;
  onSetFilters: (k: Record<string, any>) => void;
  onAggregation: (name: string, aggregation?: 'sum' | 'avg') => void;
  onReload: () => void;
  showCheckbox: boolean;
  visibleColumns: Field[];
}

export const INITIAL_DATA_TABLE_STATE: DataTableState = {
  query: '',
  selected: [],
  filters: {},
  columns: [],
  page: 1,
  count: 25,
  sort: null,
  aggregations: {
    sum: [],
    avg: [],
  },
};

export interface DataTableQueryResult {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  records: Record<string, any>[];
  total?: number;
  meta?: Record<string, any>;
}

export const DataTableContext = createContext<
  DataTableState & DataTableActions & DataTableQueryResult
>({
  ...INITIAL_DATA_TABLE_STATE,
  visibleColumns: [],
  showCheckbox: false,
  onSortChange: () => null,
  onPageChange: () => null,
  onCountChange: () => null,
  onColumnsChange: () => null,
  onAggregation: () => null,
  onSelected: () => null,
  onQuery: () => null,
  onFilter: () => null,
  onSetFilters: () => null,
  onReload: () => null,
  isLoading: false,
  isFetching: false,
  isError: false,
  records: [],
  meta: {},
  total: undefined,
});

export function useDataTable() {
  return useContext(DataTableContext);
}
