import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AlertColor, Breakpoint } from '@mui/material';
import { DefaultValues, FieldValues } from 'react-hook-form';
import { ZodSchema } from 'zod';
import { NullableFieldable } from '@/classes/types';
import { Page } from '@/resources/types';

interface ToastState {
  message: ReactNode;
  status: AlertColor;
}

interface GenericDialogOptions {
  title: string;
  description?: ReactNode;
  maxWidth?: Breakpoint;
  width?: number;
}

export interface PromptOptions<T extends FieldValues, Res = T> extends GenericDialogOptions {
  submitText?: string;
  cancelText?: string;
  fields: NullableFieldable[];
  initialValues?: DefaultValues<T>;
  schema: ZodSchema<T>;
  onSubmit?: (data: T) => Promise<Res>;
}

export interface PromptDialog<T extends FieldValues, Res> {
  open: boolean;
  promise: {
    resolve: (value: Res) => void;
    reject: () => void;
  };
  options: PromptOptions<T, Res>;
}

export interface AlertOptions extends GenericDialogOptions {
  color?: AlertColor;
  okText?: string;
}

interface AlertDialog {
  open: boolean;
  promise: {
    resolve: () => void;
    reject: () => void;
  };
  options: AlertOptions;
}

export interface ConfirmOptions extends GenericDialogOptions {
  color?: AlertColor;
  cancelText?: string;
  submitText?: string;
}

interface ConfirmDialog {
  open: boolean;
  promise: {
    resolve: () => void;
    reject: () => void;
  };
  options: ConfirmOptions;
}

interface AppState {
  routes: Page[];
  isLoading: boolean;
  isMenuOpen: boolean;
  appBar: {
    title?: ReactNode;
    actions?: ReactNode;
    back?: string | null;
  };
  appBarRef: MutableRefObject<HTMLDivElement | null>;
  alert?: AlertDialog;
  confirm?: ConfirmDialog;
  prompt?: PromptDialog<any, any>;
  promptDrawer?: PromptDialog<any, any>;
  toast?: ToastState;
  setToast: (t: ToastState | undefined) => void;
  setIsLoading: (t: boolean) => void;
  setAppBarState: (state: AppState['appBar']) => void;
  setIsMenuOpen: (t: boolean) => void;
  setAlertDialog: (state: AppState['alert']) => void;
  setConfirmDialog: (state: AppState['confirm']) => void;
  setPromptDialog: (state: AppState['prompt']) => void;
  setPromptDrawer: (state: AppState['promptDrawer']) => void;
}

export const AppContext = createContext<AppState>({
  routes: [],
  isMenuOpen: false,
  isLoading: false,
  appBar: {},
  appBarRef: { current: null },
  setIsLoading: () => null,
  setIsMenuOpen: () => null,
  setAppBarState: () => null,
  setToast: () => null,
  setAlertDialog: () => null,
  setConfirmDialog: () => null,
  setPromptDialog: () => null,
  setPromptDrawer: () => null,
});

export function useAppContext(): AppState {
  return useContext(AppContext);
}

export function AppProvider({
  children,
  routes,
  title,
}: {
  children: ReactNode;
  routes: Page[];
  title: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [appBar, setAppBarState] = useState<AppState['appBar']>({});
  const [toast, setToast] = useState<ToastState>();
  const [alert, setAlertDialog] = useState<AppState['alert']>();
  const [confirm, setConfirmDialog] = useState<AppState['confirm']>();
  const [prompt, setPromptDialog] = useState<AppState['prompt']>();
  const [promptDrawer, setPromptDrawer] = useState<AppState['promptDrawer']>();
  const appBarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (appBar.title) {
      document.title = `${appBar.title} | ${title}`;
    } else {
      document.title = title;
    }
  }, [appBar.title, title]);

  return (
    <AppContext.Provider
      value={{
        routes,
        isLoading,
        setIsLoading,
        isMenuOpen,
        setIsMenuOpen,
        setAppBarState,
        appBar,
        appBarRef,
        toast,
        setToast,
        alert,
        setAlertDialog,
        confirm,
        setConfirmDialog,
        prompt,
        setPromptDialog,
        promptDrawer,
        setPromptDrawer,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useSetIsLoading() {
  return useAppContext().setIsLoading;
}

export function useShowLoading() {
  const setIsLoading = useSetIsLoading();

  return useCallback(
    function showLoading<T>(promise: Promise<T>): Promise<T> {
      setIsLoading(true);
      return promise.finally(() => {
        setIsLoading(false);
      });
    },
    [setIsLoading],
  );
}
