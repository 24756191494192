import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid2 as Grid, styled, Typography } from '@mui/material';
import { useUpdateDesign } from '@/api/designs';
import AttachmentsCard from '@/components/Attachments/AttachmentsCard';
import DesignSection from '@/components/Designs/DesignSection';
import Form from '@/components/Form/Form';
import NoteRenderer from '@/components/Notes/NoteRenderer';
import Can from '@/components/Permissions/Can';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { DesignPayload, designPayloadSchema, OrderDesign } from '@/types';
import { useZodForm } from '@/utils/form';
import useDialogs from '@/utils/hooks/useDialogs';

const StickyHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: theme.spacing(-1),
  zIndex: 10,
  backgroundColor: theme.palette.background.paper,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderColor: theme.palette.action.selected,
  borderStyle: 'solid',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export default function ArtRequestDesignDrawer({
  artRequestId,
  orderDesign,
  open,
  onClose,
}: {
  artRequestId: number;
  orderDesign: OrderDesign;
  open: boolean;
  onClose: () => void;
}) {
  const { design } = orderDesign;

  const form = useZodForm(designPayloadSchema, design);
  const { confirm } = useDialogs();
  const updateDesignRequest = useUpdateDesign(orderDesign);

  useEffect(() => {
    if (open) {
      form.reset(design);
    }
  }, [open]);

  const decorationType = form.watch('decoration_type');

  const handleClose = (force = false) => {
    if (!form.formState.isDirty || force) {
      onClose();
      return;
    }

    confirm({
      title: 'Unsaved Changes',
      description:
        'There are unsaved changes to this design. Are you sure you want to close this drawer?',
      color: 'error',
    }).then(onClose);
  };

  const onSubmit = (values: DesignPayload) =>
    updateDesignRequest.mutateAsync(values).then(() => {
      onClose();
    });

  const handleComplete = (values: DesignPayload) =>
    onSubmit({
      ...values,
      completed_via: artRequestId,
    });

  const handleSave = (values: DesignPayload) =>
    onSubmit({
      ...values,
      completed_via: null,
    });

  return (
    <ClosableDrawer title="Requested Design" open={open} onClose={handleClose} width={1100}>
      <Form form={form} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }} gutterBottom>
              Notes
            </Typography>
            {design.request_notes && (
              <Typography variant="body2">
                <NoteRenderer note={design.request_notes} />
              </Typography>
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }} gutterBottom>
              Attachments
            </Typography>
            {design.id && (
              <AttachmentsCard
                resource="designs"
                resourceId={design.id}
                title={null}
                elevation={0}
                group="request"
              />
            )}
          </Grid>
        </Grid>
        <StickyHeader>
          <Typography variant="h5">{design.increment_id}</Typography>

          <Box ml="auto">
            <Can permission="art_requests:release">
              <LoadingButton
                type="button"
                variant="contained"
                onClick={form.handleSubmit(handleComplete)}
                loading={form.formState.isSubmitting}
                sx={{ mr: 1 }}
              >
                Save & Complete
              </LoadingButton>
            </Can>
            <LoadingButton
              type="button"
              onClick={form.handleSubmit(handleSave)}
              loading={form.formState.isSubmitting}
            >
              Save
            </LoadingButton>
          </Box>
        </StickyHeader>
        {design && <DesignSection id={design.id} decorationType={decorationType} />}
      </Form>
    </ClosableDrawer>
  );
}
