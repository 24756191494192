import { Close, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import startCase from 'lodash/startCase';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import WarningIcon from '@/components/Shared/WarningIcon';
import { Kit, KitItem } from '@/types';

function KitAttr({ prop, kit }: { prop: keyof Kit; kit: Kit }) {
  const value = kit[prop];
  if (typeof value !== 'string') {
    return null;
  }
  return (
    <Box mr={2} flexGrow={1}>
      <Typography variant="subtitle2" color="textSecondary">
        {prop === 'bin_string' ? 'Bin #' : startCase(prop)}
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
}

export default function KitBlock({
  kit,
  onEdit,
  onDelete,
  onEditKitItem,
  onRemoveKitItem,
}: {
  kit: Kit;
  onEdit?: (k: Kit) => void;
  onDelete?: (k: Kit) => void;
  onEditKitItem?: (i: KitItem) => void;
  onRemoveKitItem?: (i: KitItem) => void;
}) {
  return (
    <div>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        <KitAttr prop="bin_string" kit={kit} />
        <KitAttr prop="number" kit={kit} />
        <KitAttr prop="name" kit={kit} />
        <KitAttr prop="group" kit={kit} />

        {onEdit && (
          <Box ml="auto">
            <IconButton onClick={() => onEdit(kit)} size="large">
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        )}
      </CardContent>

      <Typography variant="subtitle2" color="textSecondary" sx={{ mx: 2, mb: 1 }}>
        Items in Kit
      </Typography>
      {kit.items && kit.items.length > 0 ? (
        <TableContainer>
          <Table size="small">
            <TableBody>
              {kit.items.map((i) => (
                <TableRow key={i.id}>
                  <TableCell>
                    <OrderItemProduct item={i.order_item} showSize />
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {i.is_backordered ? (
                      <div>
                        <span style={{ textDecoration: 'line-through' }}>{i.qty}</span>
                        <WarningIcon message="This item was backordered" />
                      </div>
                    ) : (
                      i.qty
                    )}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {onEditKitItem && (
                      <IconButton size="small" onClick={() => onEditKitItem(i)}>
                        <Edit fontSize="small" />
                      </IconButton>
                    )}
                    {onRemoveKitItem && (
                      <IconButton size="small" onClick={() => onRemoveKitItem(i)}>
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There are no items in this kit.
          </Typography>
        </CardContent>
      )}

      {kit.shipment && (
        <CardContent>
          <Typography variant="subtitle2" color="textSecondary">
            Shipment
          </Typography>
          {kit.shipment.carrier_name || `Shipment #${kit.shipment.id}`}
          {kit.shipment.tracking_url ? (
            <span>
              &nbsp;-&nbsp;
              <a href={kit.shipment.tracking_url} target="_blank" rel="noreferrer">
                {kit.shipment.tracking_number}
              </a>
            </span>
          ) : (
            <span>{kit.shipment.tracking_number}</span>
          )}
        </CardContent>
      )}

      <CardActions>
        {onDelete && (
          <Button
            type="button"
            sx={{ mt: 2 }}
            color="error"
            onClick={() => onDelete(kit)}
            size="small"
          >
            Delete Kit
          </Button>
        )}
      </CardActions>
    </div>
  );
}
