import { useState } from 'react';
import { Close, ImageSharp } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import get from 'lodash/get';
import partition from 'lodash/partition';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FieldFactory } from '@/classes';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { ART_REQUEST_ACTIVITY_TYPES } from '@/constants';
import { ArtRequestActivity, artRequestActivityPayloadSchema, Design } from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';
import AttachmentsCard from '../Attachments/AttachmentsCard';

function ArtRequestActivityList({
  activities,
  onView,
  onDelete,
}: {
  activities: ArtRequestActivity[];
  onView: (a: ArtRequestActivity) => void;
  onDelete?: (a: ArtRequestActivity) => void;
}) {
  return (
    <List>
      {activities.map((a) => (
        <ListItemButton key={a.id} component={Link} to={`/art-request-activities/${a.id}`}>
          <ListItemText
            primary={a.label}
            secondary={
              a.completed_at
                ? `Completed By ${get(a.completed_user, 'name', '?')}`
                : `Requested ${moment(a.created_at).fromNow()}`
            }
          />

          <ListItemSecondaryAction>
            {onDelete ? (
              <Tooltip title="Delete Requested Activity">
                <IconButton onClick={() => onDelete(a)} size="large">
                  <Close />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="View Completed File(s)">
                <IconButton onClick={() => onView(a)} size="large">
                  <ImageSharp />
                </IconButton>
              </Tooltip>
            )}
          </ListItemSecondaryAction>
        </ListItemButton>
      ))}
    </List>
  );
}

export default function ArtRequestActivityDrawer({
  artRequestId,
  design,
  open,
  onClose,
}: {
  artRequestId: number;
  design: Design;
  open: boolean;
  onClose: () => void;
}) {
  const { confirm, prompt } = useDialogs();
  const [viewing, setViewing] = useState<number>();

  const {
    data: activities = [],
    isLoading,
    refetch,
  } = useQuery(
    ['designActivities', design.id],
    () => {
      const params = {
        'filter[design_id]': design.id,
      };
      return axios.get('/api/art-request-activities', { params }).then(({ data }) => data.data);
    },
    {
      enabled: Boolean(design),
    },
  );

  const requestActivity = () => {
    prompt({
      title: 'Request New Activity',
      fields: [
        FieldFactory.select('type', ART_REQUEST_ACTIVITY_TYPES).withHelp(
          'The activity will be auto-assigned based on this type',
        ),
        FieldFactory.textarea('note'),
      ],
      schema: artRequestActivityPayloadSchema.omit({ design_id: true, art_request_id: true }),
      onSubmit: (v) =>
        axios.post('/api/art-request-activities', {
          design_id: design.id,
          art_request_id: artRequestId,
          ...v,
        }),
    }).then(() => {
      refetch();
    });
  };

  const [currentActivities, pastActivities] = partition(
    activities,
    (a: ArtRequestActivity) => a.art_request_id === artRequestId,
  );

  const [outstandingActivities, completedActivities] = partition(
    currentActivities,
    (a: ArtRequestActivity) => !a.completed_at,
  );

  const onDelete = (a: ArtRequestActivity) => {
    confirm({
      title: 'Delete Requested Activity',
      description: 'Are you sure you want to delete this activity?',
    }).then(() => {
      axios.delete(`/api/art-request-activities/${a.id}`).then(() => {
        refetch();
      });
    });
  };

  const onView = (a: ArtRequestActivity) => {
    setViewing(a.id);
  };

  return (
    <ClosableDrawer
      open={open}
      onClose={onClose}
      title={`Art Activities for ${design.increment_id}`}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          <Typography variant="subtitle2">Pending Activities</Typography>

          {outstandingActivities.length > 0 ? (
            <ArtRequestActivityList
              activities={outstandingActivities}
              onDelete={onDelete}
              onView={onView}
            />
          ) : (
            <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
              No activities are pending.
            </Typography>
          )}

          <Button onClick={requestActivity} variant="contained" sx={{ mt: 2 }} size="small">
            Request New Activity
          </Button>

          <Box mt={3}>
            <Typography variant="subtitle2" gutterBottom>
              Completed Activities
            </Typography>
            {completedActivities.length > 0 && (
              <div>
                <Typography variant="subtitle2" color="textSecondary">
                  Current Art Request
                </Typography>
                <ArtRequestActivityList activities={completedActivities} onView={onView} />
              </div>
            )}
            {pastActivities.length > 0 && (
              <div>
                <Typography variant="subtitle2" color="textSecondary">
                  Past Art Requests
                </Typography>
                <ArtRequestActivityList activities={pastActivities} onView={onView} />
              </div>
            )}

            {pastActivities.length === 0 && completedActivities.length === 0 ? (
              <Typography color="textSecondary" variant="body2">
                No activities have been completed for this design.
              </Typography>
            ) : null}
          </Box>
        </div>
      )}

      <Dialog open={!!viewing} onClose={() => setViewing(undefined)} maxWidth="lg">
        <div style={{ width: 600 }}>
          {viewing && (
            <AttachmentsCard
              elevation={0}
              title="Files"
              resourceId={viewing}
              resource="art-request-activities"
            />
          )}
        </div>
      </Dialog>
    </ClosableDrawer>
  );
}
