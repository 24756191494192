import { Alert, Box, LinearProgress } from '@mui/material';
import { useGetAddressesForOrder } from '@/api/addresses';
import { useRecord } from '@/utils/genericResource';
import { isInventory } from '@/utils/shipping';
import NoteViewer from '../Notes/NoteViewer';
import OrderShippingInventory from './OrderShippingInventory';
import OrderShippingKit from './OrderShippingKit';
import OrderShippingSimple from './OrderShippingSimple';

export default function ShippingForOrder() {
  const order = useRecord('ordersToShip');

  const { data: addresses, isFetching, refetch } = useGetAddressesForOrder('orders', order.id);

  return (
    <Box mb={2}>
      <NoteViewer resource="orders" resourceId={order.id} tag="shipping" />

      {isFetching && <LinearProgress />}

      {addresses && addresses.length > 0 ? (
        <>
          {order.shipping_mode === 'bulk' && (
            <OrderShippingSimple
              orderId={order.id}
              customerId={order.customer.id}
              address={addresses[0]}
            />
          )}

          {order.shipping_mode === 'kits' && (
            <OrderShippingKit
              orderId={order.id}
              customerId={order.customer.id}
              addresses={addresses}
              refetch={refetch}
            />
          )}

          {isInventory(order.shipping_mode) && <OrderShippingInventory order={order} />}
        </>
      ) : addresses ? (
        <Alert severity="error">There are no addresses for this order.</Alert>
      ) : null}
    </Box>
  );
}
