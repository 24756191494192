import { AssignmentInd } from '@mui/icons-material';
import SubmitIcon from '@mui/icons-material/AssignmentTurnedIn';
import RejectIcon from '@mui/icons-material/Block';
import { AlertColor, Typography } from '@mui/material';
import axios from 'axios';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { z } from 'zod';
import { ButtonAction, CustomAction, FieldFactory, Resource } from '@/classes';
import { OnClickProps } from '@/classes/types';
import ArtRequestEditor from '@/components/ArtRequests/ArtRequestEditor';
import DueDateBar from '@/components/Shared/DueDateBar';
import Text from '@/components/Text/Text';
import UserLabel from '@/components/Users/UserLabel';
import { ART_REQUEST_STATUS_COLORS, ART_REQUEST_TYPES } from '@/constants';
import {
  AppResponse,
  ArtRequest,
  artRequestPayloadSchema,
  genericModelReferenceSchema,
} from '@/types';
import { getBusinessNameById } from '@/utils/getBusinessName';

const ArtRequestActions = (values: ArtRequest) => {
  const completeRequest = ({ dialogs, toast, showLoading, updateRecord }: OnClickProps) => {
    let title = 'Complete Art Request';
    let description = 'Are you sure this request is complete?';
    let color: AlertColor | undefined = undefined;

    const { artRequestMeta } = window;
    if (!artRequestMeta) {
      return;
    }

    if (values.type === 'release') {
      if (artRequestMeta._productionProofCount.some((t) => t === 0)) {
        title = 'Missing Proofs';
        description =
          'Not all layouts have a production proof. Are you sure you want to complete this request?';
        color = 'warning';
      } else if (artRequestMeta._completedAts.some((t) => !t)) {
        title = 'Incomplete Designs';
        description = 'There are incomplete designs. Are you sure you want to release this order?';
        color = 'warning';
      }
      description +=
        ' This will send a production approval to the LOB before being released to production.';
    }

    if (values.type === 'proof') {
      if (artRequestMeta._customerProofCount.some((t) => t === 0)) {
        title = 'Missing Proofs';
        description =
          'Not all layouts have a customer proof. Are you sure you want to complete this request?';
        color = 'warning';
      }

      if (values.send_approval_on_complete) {
        description += ' This will send proofs and order approval to the customer.';
      }
    }

    dialogs.confirm({ title, description, color }).then(() => {
      showLoading(
        axios.post<ArtRequest>(`/api/art-requests/${values.id}/complete`).then(({ data }) => {
          toast('Art Request has been marked as complete');
          updateRecord(data);
        }),
      );
    });
  };

  const rejectRequest = ({ dialogs, updateRecord, toast }: OnClickProps) => {
    dialogs
      .prompt({
        title: 'Reject Art Request',
        description: 'Please explain why this art request rejected:',
        fields: [FieldFactory.textarea('reason')],
        schema: z.object({
          reason: z.string(),
        }),
        onSubmit: (v: { reason: string }) => axios.post(`/api/art-requests/${values.id}/reject`, v),
      })
      .then(({ data }) => {
        toast('Art Request has been rejected');
        updateRecord(data);
      });
  };

  if (!values.completed_at && !values.rejected_at) {
    return [
      new ButtonAction('Mark as Complete', completeRequest).withIcon(SubmitIcon),
      new ButtonAction('Reject', rejectRequest).withIcon(RejectIcon),
    ];
  }

  return [
    new CustomAction('status', () => {
      if (values.rejected_at) {
        return <div>Rejected -{moment(values.rejected_at).format('lll')}</div>;
      }
      if (values.completed_at) {
        return <div>Completed -{moment(values.completed_at).format('lll')}</div>;
      }
      return <span />;
    }),
  ];
};

export default function artRequests({ businesses }: AppResponse) {
  return new Resource<ArtRequest>('Art Requests')
    .withRelations(['order'])
    .withSchema(artRequestPayloadSchema)
    .getTitleUsing((r) => r.label)
    .setCreatable(false)
    .setDeletable(false)
    .withDefaultSort('due_at')
    .withDefaultFilters({
      bucket: 'open',
    })
    .getSingleActionsUsing(ArtRequestActions)
    .withBulkActions([
      new ButtonAction('Assign', ({ dialogs, selected, onReloadRecord }) => {
        dialogs
          .prompt({
            title: 'Assign to Artist',
            fields: [
              FieldFactory.belongsTo('assignee', 'users').with({
                requestParams: {
                  artist: 1,
                  sort: 'name',
                },
                getSubtitle: (u) => getBusinessNameById(businesses, u.business_id),
              }),
            ],
            schema: z.object({
              assignee: genericModelReferenceSchema.nullish(),
            }),
            onSubmit: (v) =>
              Promise.all(
                selected.map((arId) =>
                  axios.post(`/api/art-requests/${arId}/assign`, { user_id: v.assignee?.id }),
                ),
              ),
          })
          .then(() => onReloadRecord());
      }).withIcon(AssignmentInd),
    ])
    .withFilters([
      FieldFactory.radio('bucket', {
        open: 'Open',
        mine: 'Assigned to Me',
        unassigned: 'Unassigned',
        pending: 'Pending Activity',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.text('increment_id')
        .withLabel('#')
        .sortable()
        .renderCellUsing((v: ArtRequest['increment_id'], r: ArtRequest) => (
          <Text primary={r.increment_id} secondary={startCase(r.type)} />
        )),
      FieldFactory.belongsTo('assignee', 'users')
        .filterable()
        .sortable('assignee.name')
        .renderCellUsing((u) => <UserLabel user={u} />),
      FieldFactory.belongsTo('order', 'orders').filterable().sortable('order.id'),
      FieldFactory.belongsTo('order.customer', 'customers')
        .withLabel('Customer')
        .filterable('filter[order.customer_id]')
        .sortable('order.customer.name'),
      FieldFactory.date('order.drop_dead_date').withLabel('Drop Dead').sortable(),
      FieldFactory.timestamp('due_at')
        .sortable()
        .withLabel('Due Date')
        .renderCellUsing((v: ArtRequest['due_at'], r: ArtRequest) => (
          <div>
            <Typography variant="body2">{v ? moment(v).format('L') : '(none)'}</Typography>
            {v && !r.completed_at && !r.rejected_at && <DueDateBar end={v} start={r.created_at} />}
          </div>
        )),
      FieldFactory.status('status', ART_REQUEST_STATUS_COLORS).filterable().withLabel('Status'),
      FieldFactory.select('type', ART_REQUEST_TYPES).sortable().filterable(),
      FieldFactory.timestamp('status_updated_at').withLabel('Status Changed').sortable(),
      FieldFactory.timestamp('completed_at').sortable(),
      FieldFactory.timestamp('rejected_at').sortable(),
    ])
    .editUsing(ArtRequestEditor);
}
