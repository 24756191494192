import { useEffect, useMemo, useRef, useState } from 'react';
import { FileDownload, Refresh } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import {
  ColumnDef,
  ColumnSort,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import ColumnsDialog from '@/components/DataTable/ColumnsDialog';
import ReactTable from '@/components/Shared/ReactTable';
import searchCollection from '@/utils/searchCollection';
import truthy from '@/utils/truthy';
import DebouncedTextField from './DebouncedTextField';

export default function PaginatedTable<T extends Record<string, any>>({
  rows,
  columns,
  searchable,
  initialRowsPerPage = 25,
  initialSortBy,
  size,
  columnVisibility,
  filename = 'export',
  enableToolbar = true,
  onReload,
  isFetching,
}: {
  rows: T[];
  columns: (ColumnDef<T> | false | null)[];
  searchable?: string[];
  initialRowsPerPage?: number;
  initialSortBy?: ColumnSort;
  size?: 'small' | 'medium';
  columnVisibility?: Record<string, boolean>;
  filename?: string;
  enableToolbar?: boolean;
  onReload?: () => void;
  isFetching?: boolean;
}) {
  const [query, setQuery] = useState('');
  const prevLength = useRef<number>();

  const filteredItems = useMemo(() => {
    return searchable ? searchCollection(rows, query, searchable) : rows;
  }, [rows, query, searchable?.join('|')]);

  const stableColumns = useMemo(() => {
    return truthy(columns);
  }, [columns]);

  const table = useReactTable({
    data: filteredItems,
    columns: stableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnVisibility,
      sorting: initialSortBy ? [initialSortBy] : [],
      pagination: {
        pageSize: initialRowsPerPage,
      },
    },
  });

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    filename, // export file name (without .csv)
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  // Go to last page when item added
  const numItems = rows.length;
  useEffect(() => {
    if (prevLength.current && numItems > prevLength.current) {
      table.setPageIndex(table.getPageCount() - 1);
    }
    prevLength.current = numItems;
  }, [numItems]);

  return (
    <>
      {enableToolbar && (
        <Box
          sx={{
            px: 1,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box display="flex" alignItems="center">
            {isFetching ? (
              <Box p={1.5} display="inline-flex">
                <CircularProgress size={24} />
              </Box>
            ) : onReload ? (
              <Tooltip title="Reload" placement="top">
                <IconButton onClick={onReload} size="large">
                  <Refresh />
                </IconButton>
              </Tooltip>
            ) : null}

            <ColumnsDialog
              allColumns={table
                .getAllColumns()
                .filter((c) => c.getCanHide())
                .map((c) => ({
                  name: c.id,
                  label: c.columnDef.header as string,
                }))}
              toggleColumn={(column) => {
                table
                  .getAllColumns()
                  .find((c) => c.id === column)
                  ?.toggleVisibility();
              }}
              columns={table
                .getAllColumns()
                .filter((c) => c.getIsVisible())
                .map((c) => c.id)}
            />
            <Tooltip title="Export to CSV" placement="top">
              <IconButton
                onClick={() => {
                  const rowData = table.getFilteredRowModel().rows.map((row) => {
                    const columns: Record<string, any> = {};
                    row.getAllCells().forEach(({ column, getValue }) => {
                      if (!('accessorKey' in column.columnDef)) {
                        return;
                      }
                      const value = getValue();
                      columns[
                        typeof column.columnDef.header === 'string'
                          ? column.columnDef.header
                          : column.id
                      ] = value == null ? '' : getValue();
                    });
                    return columns;
                  });
                  const csv = generateCsv(csvConfig)(rowData);
                  download(csvConfig)(csv);
                }}
                size="large"
              >
                <FileDownload />
              </IconButton>
            </Tooltip>
          </Box>

          <Box display="flex" alignItems="center">
            {searchable && (
              <DebouncedTextField
                initialValue={query}
                onChange={(value: string) => {
                  setQuery(value);
                  table.setPageIndex(0);
                }}
                size="small"
                type="search"
                label="Search"
                fullWidth
                style={{ maxWidth: 300 }}
              />
            )}
          </Box>
        </Box>
      )}

      <ReactTable table={table} size={size} isFetching={isFetching} />
    </>
  );
}
