import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Issue } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';

export function getQueryKey(issuableType: Issue['issuable_type'], issuableId: number) {
  return makeResourceQueryKey(
    issuableType === 'order' ? 'orders' : 'purchaseOrders',
    issuableId,
    'issues',
  );
}

export function useGetIssues(issuableType: Issue['issuable_type'], issuableId: number) {
  return useQuery(getQueryKey(issuableType, issuableId), () =>
    axios
      .get<{
        data: Issue[];
      }>(`/api/${issuableType === 'order' ? 'orders' : 'purchase-orders'}/${issuableId}/issues`)
      .then(({ data }) => data.data),
  );
}

export function useCreateIssue(issuableType: Issue['issuable_type'], issuableId: number) {
  const queryClient = useQueryClient();

  return useMutation((payload: { details: string; type: string; order_item_ids?: number[] }) =>
    axios
      .post<Issue>(
        `/api/${issuableType === 'order' ? 'orders' : 'purchase-orders'}/${issuableId}/issues`,
        payload,
      )
      .then(({ data }) => {
        queryClient.setQueryData<Issue[]>(getQueryKey(issuableType, issuableId), (prev) => {
          if (!prev) {
            return prev;
          }
          return [...prev, data];
        });
        return data;
      }),
  );
}

export function useResolveIssue(issue: Issue) {
  const queryClient = useQueryClient();

  return useMutation((payload: { resolution: string }) =>
    axios.post<Issue>(`/api/issues/${issue.id}/resolve`, payload).then(({ data }) => {
      queryClient.setQueryData<Issue[]>(
        getQueryKey(issue.issuable_type, issue.issuable.id),
        (prev) => {
          if (!prev) {
            return prev;
          }
          return prev.map((i) => (i.id === data.id ? data : i));
        },
      );
      queryClient.invalidateQueries(makeResourceQueryKey('ordersToPurchase', issue.issuable.id));
      return data;
    }),
  );
}

export function useChangeIssueStatus(issue: Issue) {
  const queryClient = useQueryClient();

  return useMutation((payload: { status: Issue['status'] }) =>
    axios.post<Issue>(`/api/issues/${issue.id}/status`, payload).then(({ data }) => {
      queryClient.setQueryData<Issue[]>(
        getQueryKey(issue.issuable_type, issue.issuable.id),
        (prev) => {
          if (!prev) {
            return prev;
          }
          return prev.map((i) => (i.id === data.id ? data : i));
        },
      );
      return data;
    }),
  );
}
