import { useState } from 'react';
import { Calculate, Search } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useUpdateShippableOrderItem, useGetShippableItems } from '@/api/orderItems';
import '@/api/shipping';
import { FieldFactory } from '@/classes';
import InventoryKitAlert from '@/components/Inventory/InventoryKitAlert';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import PrintMenu from '@/components/Print/PrintMenu';
import ProductFinderDialog from '@/components/Products/ProductFinderDialog';
import SkuLabel from '@/components/Products/SkuLabel';
import TextButton from '@/components/Text/TextButton';
import { Order, OrderItem, orderItemPayloadSchema, ProductVariant } from '@/types';
import { useRecord } from '@/utils/genericResource';
import useDialogs from '@/utils/hooks/useDialogs';
import { isInventoryKit } from '@/utils/shipping';

export default function OrderInventoryMapping() {
  const order = useRecord<Order>();
  const orderId = order.id;
  const isKit = isInventoryKit(order.shipping_mode);
  const [choosing, setChoosing] = useState<OrderItem>();
  const { prompt } = useDialogs();

  const { data: items = [], isLoading } = useGetShippableItems(orderId);
  const updateOrderItemRequest = useUpdateShippableOrderItem(orderId);

  const onVariantChosen = (variant: ProductVariant) => {
    if (choosing) {
      updateOrderItemRequest.mutateAsync({ id: choosing.id, inventory_variant: variant });
      setChoosing(undefined);
    }
  };

  const onUpdateUnits = (item: OrderItem) => {
    prompt({
      title: 'Update Unit Conversion',
      description: (
        <Typography variant="body2" color="textSecondary">
          When adding items to inventory, you can optionally convert the units to a different unit
          type. For example, if you ordered 2 cases of 12 bottles from the vendor, you can set this
          value to 12 to individually add each of the 24 bottles to inventory.
        </Typography>
      ),
      fields: [
        FieldFactory.number('inventory_unit_conversion')
          .withInputProps({
            step: 0.01,
          })
          .withLabel('Unit Conversion')
          .withSize('medium'),
      ],
      schema: orderItemPayloadSchema.pick({ inventory_unit_conversion: true }),
      initialValues: {
        inventory_unit_conversion: item.inventory_unit_conversion,
      },
      onSubmit: (v) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v }),
    });
  };

  const onUpdateQtyPerKit = (item: OrderItem) => {
    prompt({
      title: 'Update Quantity Per Kit',
      description: (
        <Typography variant="body2" color="textSecondary">
          The Quantity Per Kit determines how many of each item will be included in a single kit. Be
          sure that the quantity chosen can be divisible evenly based on the quantity of each item
          and the amount that should be included in each kit. This value will default to 1 unless
          otherwise indicated.
        </Typography>
      ),
      fields: [
        FieldFactory.number('qty_per_kit')
          .withInputProps({
            step: 1,
          })
          .withLabel('Qty Per Kit')
          .withSize('medium'),
      ],
      schema: orderItemPayloadSchema.pick({ qty_per_kit: true }),
      initialValues: {
        qty_per_kit: item.qty_per_kit,
      },
      onSubmit: (v) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v }),
    });
  };

  return (
    <Card>
      <CardHeader title="Item to SKU Mapping" />
      <CardContent>
        When items are ready to ship, they will be added to inventory using this mapping.
      </CardContent>

      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>SKU Mapping</TableCell>
              {isKit && <TableCell>Qty Per Kit</TableCell>}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <OrderItemProduct item={item} showSize />
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <div>{item.qty}</div>

                    {item.inventory_unit_conversion != null ? (
                      <Chip sx={{ ml: 1 }} label={`x ${item.inventory_unit_conversion}`} />
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell>
                  {item.inventory_variant ? (
                    <SkuLabel variant={item.inventory_variant} />
                  ) : (
                    <Alert severity="warning" sx={{ maxWidth: 300 }}>
                      No SKU Mapped
                    </Alert>
                  )}
                </TableCell>
                {isKit && (
                  <TableCell>
                    <TextButton onClick={() => onUpdateQtyPerKit(item)}>
                      {item.qty_per_kit ?? 1}
                    </TextButton>
                  </TableCell>
                )}
                <TableCell>
                  <Tooltip title="Find SKU">
                    <IconButton onClick={() => setChoosing(item)}>
                      <Search />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add Unit Conversion">
                    <IconButton onClick={() => onUpdateUnits(item)}>
                      <Calculate />
                    </IconButton>
                  </Tooltip>
                  {item.inventory_variant ? (
                    <PrintMenu model="variant" id={item.inventory_variant.id} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {isKit && items?.length ? <InventoryKitAlert items={items} /> : null}

      <ProductFinderDialog
        open={!!choosing}
        mode="variant"
        onSuccess={onVariantChosen}
        onCancel={() => setChoosing(undefined)}
        isLoading={updateOrderItemRequest.isLoading}
        customerId={order.customer.id}
        defaultFacets={{ customer: order.customer.name }}
      />
    </Card>
  );
}
