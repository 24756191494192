import { ReactNode, useState } from 'react';
import { Divider, Drawer, Hidden, List, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppContext } from '@/contexts/AppContext';
import { NavItem } from '@/resources/types';
import { useRequiresPermissionAndRole } from '@/utils/hooks/permissions';
import MenuItem from './NavigationMenuItem';
import UserMenuSection from './UserMenuSection';

const NavDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    width: theme.drawerWidth || 225,
    background: theme.drawerColor || theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.drawerColor || theme.palette.primary.main),
  },
}));

const LogoImage = styled('img')({
  height: 59,
  width: 219,
  maxHeight: '100%',
  padding: '10px 24px',
  objectFit: 'contain',
});

export default function AppNavigation({
  logo,
  pages,
  userActions,
  children,
}: {
  logo: string | ReactNode;
  pages: NavItem[];
  children?: ReactNode;
  userActions?: ReactNode;
}) {
  const [itemsOpen, setItemsOpen] = useState<Record<string, boolean>>({});
  const { isMenuOpen, setIsMenuOpen } = useAppContext();
  const onClose = () => setIsMenuOpen(false);
  const requiresPermissionAndRole = useRequiresPermissionAndRole();

  const toggleItemOpen = (name: string) => {
    setItemsOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const drawer = (
    <>
      <Link to="/">{typeof logo === 'string' ? <LogoImage src={logo} /> : logo}</Link>
      <Divider />
      {children}
      <UserMenuSection>{userActions}</UserMenuSection>
      <Divider />
      <List>
        {pages.filter(requiresPermissionAndRole).map((page) => (
          <MenuItem
            key={page.name}
            level={0}
            toggleOpen={toggleItemOpen}
            open={itemsOpen}
            page={page}
          />
        ))}
      </List>
    </>
  );

  return (
    <>
      <Hidden mdUp>
        <NavDrawer
          variant="temporary"
          anchor="left"
          open={isMenuOpen}
          onClose={onClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </NavDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <NavDrawer variant="permanent" anchor="left">
          {drawer}
        </NavDrawer>
      </Hidden>
    </>
  );
}
