import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import { useGetCustomer } from '@/api/customers';
import { useUpdateRequestedDesign } from '@/api/designs';
import { FieldFactory } from '@/classes';
import AttachmentsCard from '@/components/Attachments/AttachmentsCard';
import AttachmentsList from '@/components/Attachments/AttachmentsList';
import DesignColors from '@/components/Designs/DesignColors';
import DrawerButtons from '@/components/Form/DrawerButtons';
import Form from '@/components/Form/Form';
import FormErrors from '@/components/Form/FormErrors';
import FormField from '@/components/Form/FormField';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import { useConfig } from '@/contexts/AppConfigContext';
import { isScreenPrint } from '@/helpers';
import { DesignPayload, designPayloadSchema, Order, OrderDesign, Quote } from '@/types';
import { handleLaravelErrors, useZodForm } from '@/utils/form';
import { useRecord } from '@/utils/genericResource';
import { maybeAddQueryParams } from '@/utils/query';

function SectionHeader({
  title,
  subtitle,
  first,
}: {
  title: string;
  subtitle: string;
  first?: boolean;
}) {
  return (
    <Box mb={2} mt={first ? 0 : 3}>
      <Typography variant="subtitle1">
        <span style={{ fontWeight: 500 }}>{title}</span>
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {subtitle}
      </Typography>
    </Box>
  );
}

export default function RequestedDesignDrawer({
  orderDesign,
  open,
  onClose,
}: {
  orderDesign: OrderDesign;
  open: boolean;
  onClose: () => void;
}) {
  const form = useZodForm(designPayloadSchema, orderDesign.design);
  const decorationType = form.watch('decoration_type');
  const { decorationLocations } = useConfig();
  const decorationUnit = decorationType?.unit;
  const { data: customer } = useGetCustomer(useRecord<Order | Quote>().customer.id);
  const updateDesignRequest = useUpdateRequestedDesign(orderDesign);

  useEffect(() => {
    if (open) {
      form.reset(orderDesign.design);
    }
  }, [open]);

  const onSubmit = (values: DesignPayload) =>
    updateDesignRequest
      .mutateAsync(
        pick(values, [
          'name',
          'decoration_type',
          'request_notes',
          'location',
          'complexity',
          'special_design_type',
          'colors',
        ]),
      )
      .then(() => {
        onClose();
      })
      .catch((e) => {
        if (e.response.status === 422) {
          handleLaravelErrors(form)(e);
        }
        throw e;
      });

  let locationNames: string[] = [];
  if (decorationType && decorationType.name in decorationLocations) {
    locationNames = decorationLocations[decorationType.name].map((l) => l.name);
  }

  return (
    <ClosableDrawer
      title={orderDesign?.design.increment_id || 'Edit Design'}
      open={open}
      onClose={onClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        <SectionHeader
          title="Required Details"
          subtitle="Enter the basic details of the design you are requesting."
          first
        />
        <Box mb={1}>
          <FormField field={FieldFactory.text('name').setRequired()} />
        </Box>
        <Box mb={1}>
          <FormField
            field={FieldFactory.belongsTo('decoration_type', 'decorationTypes').setRequired()}
          />
        </Box>
        <Box mb={1}>
          <FormField
            field={
              locationNames.length > 0
                ? FieldFactory.select('location', locationNames)
                : FieldFactory.text('location')
            }
          />
        </Box>
        {isScreenPrint(decorationUnit) && (
          <div>
            <SectionHeader
              title="Colors"
              subtitle="Optionally enter the colors in your design so you can apply ink changes."
            />
            <DesignColors decorationUnit="colors" customer={customer} freeSolo={false} />
          </div>
        )}
        <SectionHeader
          title="Optional Details"
          subtitle="Enter other design details to assist with pricing."
        />
        {decorationUnit && (
          <Box mb={1}>
            <FormField
              field={FieldFactory.number('complexity').withLabel(
                `# of ${startCase(decorationUnit)}`,
              )}
            />
          </Box>
        )}
        <Box mb={1}>
          <FormField field={FieldFactory.belongsTo('special_design_type', 'specialDesignTypes')} />
        </Box>
        <SectionHeader
          title="Notes"
          subtitle="Please describe what you are asking the artist to create."
        />
        <FormField field={FieldFactory.textarea('request_notes').withLabel('Notes')} />
        <SectionHeader
          title="Attachments"
          subtitle="Upload logos, artwork, or examples here. Vector files preferred. Logos from the customer's brand files are automatically included."
        />
        {customer?.brand_files && (
          <AttachmentsList
            attachments={customer.brand_files.map((br) => ({
              id: br.id,
              file: br.file,
              name: br.name,
              image: br.image,
              url: br.file,
              download_url: maybeAddQueryParams(br.file, { dl: 1 }) as string,
              group: 'brand',
              size: null,
              created_at: br.created_at,
            }))}
          />
        )}
        {orderDesign && (
          <AttachmentsCard
            resourceId={orderDesign.design.id}
            resource="designs"
            group="request"
            title={null}
            elevation={0}
          />
        )}

        <FormErrors form={form} />

        <DrawerButtons>
          <LoadingButton type="submit" variant="contained" loading={form.formState.isSubmitting}>
            Save
          </LoadingButton>
        </DrawerButtons>
      </Form>
    </ClosableDrawer>
  );
}
