import { useQueryClient } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OnClickProps } from '@/classes/types';
import { useDataTable } from '@/components/DataTable/DataTableContext';
import { useAppContext, useShowLoading } from '@/contexts/AppContext';
import {
  useMaybeCurrentResource,
  useOnReloadRecord,
  useRecordId,
  useUpdateRecord,
} from '@/utils/genericResource';
import useGetResource from '@/utils/hooks/useGetResource';
import useDialogs from './useDialogs';
import useToast from './useToast';

export default function useOnClickProps(): OnClickProps {
  const { appBar, setIsLoading } = useAppContext();
  const showLoading = useShowLoading();
  const dialogs = useDialogs();
  const navigate = useNavigate();
  const toast = useToast();
  const form = useFormContext();
  const queryClient = useQueryClient();

  const { selected, onReload: onReloadTable } = useDataTable();

  const resourceKey = useMaybeCurrentResource();
  const getResource = useGetResource();
  const resource = resourceKey ? getResource(resourceKey) : undefined;
  const recordId = useRecordId();
  const updateRecord = useUpdateRecord();
  const onReloadRecord = useOnReloadRecord();

  return {
    change: form ? form.setValue : () => {},
    dialogs,
    navigate,
    back: appBar.back,
    toast,
    setIsLoading,
    showLoading,

    selected,
    onReloadTable,

    resource,
    form: resource?.getFormName(),
    onReloadRecord,

    queryClient,
    recordId,
    updateRecord,
  };
}
