import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { OrderCommission, OrderCommissionUpdatePayload } from '@/types';
import { makeResourceQueryKey } from '@/utils/genericResource';
import { useHasPermission } from '@/utils/hooks/permissions';

interface SalesRepGroup {
  id: number;
  name: string;
  email: string;
  method: 'gp' | 'cogs';
  percent: number;
  earned: number;
  realized: number;
  dinged: number;
  pending: number;
  lines: OrderCommission[];
}

export function useGetOrderCommissions(orderId: number) {
  const hasPermission = useHasPermission();

  return useQuery(
    makeResourceQueryKey('orders', orderId, 'commissions'),
    () =>
      axios
        .get<{
          sales_reps: SalesRepGroup[];
          data: OrderCommission[];
          next: number;
        }>(`/api/orders/${orderId}/commissions`)
        .then(({ data }) => data),
    { enabled: hasPermission('read:order_commissions') },
  );
}

export function useCreateOrderCommission(orderId: number) {
  const queryClient = useQueryClient();
  return useMutation((payload: { user_id: number; amount: number; description: string }) =>
    axios.post(`/api/orders/${orderId}/commissions`, payload).then(() => {
      queryClient.invalidateQueries(makeResourceQueryKey('orders', orderId, 'commissions'));
    }),
  );
}

export function useDeleteOrderCommission(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation((commissionId: number) =>
    axios.delete(`/api/orders/${orderId}/commissions/${commissionId}`).then(() => {
      queryClient.invalidateQueries(makeResourceQueryKey('orders', orderId, 'commissions'));
    }),
  );
}

export function useResetOrderCommissions(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation(() =>
    axios.post(`/api/orders/${orderId}/reset-commissions`).then(() => {
      queryClient.invalidateQueries(makeResourceQueryKey('orders', orderId, 'commissions'));
    }),
  );
}

export function useUpdateCommissionUsers(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation((payload: OrderCommissionUpdatePayload) =>
    axios.put(`/api/orders/${orderId}/commission-users`, payload).then(() => {
      queryClient.invalidateQueries(makeResourceQueryKey('orders', orderId, 'commissions'));
    }),
  );
}
