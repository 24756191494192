import { Alert, Button, TableCell, TableRow } from '@mui/material';
import get from 'lodash/get';
import { usePurchaseOrderDesign, useUnpurchaseOrderDesign } from '@/api/designs';
import { ArtRequest, OrderDesign } from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';

export default function OrderDesignPurchasing({
  orderDesign,
  status,
}: {
  orderDesign: OrderDesign;
  status: ArtRequest['status'];
}) {
  const { confirm } = useDialogs();
  const canEdit = !(status === 'completed' || status === 'rejected');
  const purchaseRequest = usePurchaseOrderDesign(orderDesign);
  const unpurchaseRequest = useUnpurchaseOrderDesign(orderDesign);

  const doInHouse = () => {
    confirm({
      title: 'Create In House',
      description: `Are you sure you want to print/cut ${orderDesign.qty_to_purchase} names/numbers in house?`,
    }).then(() => {
      purchaseRequest.mutate(true);
    });
  };

  const purchase = () => {
    const productName = get(orderDesign.design, 'product.name', 'items');
    confirm({
      title: 'Create Purchase Order',
      description: `Are you sure you want to create a PO with ${orderDesign.qty_to_purchase} ${productName}?`,
    }).then(() => {
      purchaseRequest.mutate(false);
    });
  };

  const undo = () => {
    confirm({
      title: 'Undo',
      description: 'Are you sure you want to undo the purchasing design for these names/numbers?',
      color: 'error',
    }).then(() => {
      unpurchaseRequest.mutate();
    });
  };

  if (orderDesign.qty_to_purchase == null) {
    return null;
  }

  if (orderDesign.qty_to_purchase === 0) {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Alert
            severity="success"
            action={
              <div>
                {!orderDesign.drops_in_house && (
                  <Button
                    size="small"
                    color="inherit"
                    component="a"
                    target="_blank"
                    href={`/purchase-orders/${orderDesign.purchase_order_items?.[0].purchase_order_id}`}
                  >
                    View PO
                  </Button>
                )}
                {canEdit && (
                  <Button size="small" color="inherit" onClick={undo}>
                    Undo
                  </Button>
                )}
              </div>
            }
          >
            This design
            {orderDesign.drops_in_house ? ' is being done in house' : ' has been purchased'}
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  if (!canEdit) {
    return null;
  }

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Alert
          severity="error"
          action={
            <div>
              <Button size="small" variant="outlined" color="inherit" onClick={purchase}>
                Purchase From Vendor
              </Button>
              <Button size="small" sx={{ ml: 1 }} color="inherit" onClick={doInHouse}>
                Do In-House
              </Button>
            </div>
          }
        >
          {orderDesign.qty_to_purchase} items need to be purchased for this design
        </Alert>
      </TableCell>
    </TableRow>
  );
}
