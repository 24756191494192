import { Card, CardHeader, List } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import OrderListItem from '@/components/Orders/OrderListItem';
import { Order } from '@/types';
import useGetResource from '@/utils/hooks/useGetResource';
import usePushWithContext from '@/utils/hooks/usePushWithContext';
import truthy from '@/utils/truthy';

export default function RelatedOrders({ orderId }: { orderId: number }) {
  const getResource = useGetResource();

  const { data: related } = useQuery(['order', orderId, 'related'], () =>
    axios
      .get<{
        parent: Order | null;
        children: Order[];
        siblings: Order[];
      }>(`/api/orders/${orderId}/related`)
      .then(({ data }) => data),
  );

  const parent = related?.parent;
  const children = related?.children || [];
  const siblings = related?.siblings || [];
  const orderResource = getResource('orders');
  const combinedOrders = truthy([parent, ...siblings, ...children]);

  const push = usePushWithContext(
    combinedOrders.map((o) => ({
      key: o.id,
      label: orderResource.getTitle(o),
      href: `/orders/${o.id}`,
    })),
  );

  if (combinedOrders.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Related Orders" />
      {parent && (
        <>
          <CardHeader
            title="Parent Order"
            titleTypographyProps={{ variant: 'subtitle2' }}
            style={{ paddingBottom: 0 }}
          />
          <List dense>
            <OrderListItem order={parent} push={push} />
          </List>
        </>
      )}

      {siblings[0] && (
        <>
          <CardHeader
            title="Sibling Orders"
            titleTypographyProps={{ variant: 'subtitle2' }}
            style={{ paddingBottom: 0 }}
          />
          <List dense>
            {siblings.map((s) => (
              <OrderListItem key={s.id} order={s} push={push} />
            ))}
          </List>
        </>
      )}

      {children[0] && (
        <>
          <CardHeader
            title="Child Orders"
            titleTypographyProps={{ variant: 'subtitle2' }}
            style={{ paddingBottom: 0 }}
          />
          <List dense>
            {children.map((c) => (
              <OrderListItem key={c.id} order={c} push={push} />
            ))}
          </List>
        </>
      )}
    </Card>
  );
}
