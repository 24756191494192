import { Delete, Download, Edit, Sync, SyncAlt } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Switch,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import pick from 'lodash/pick';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import CreateButton from '@/components/Buttons/CreateButton';
import FormField from '@/components/Form/FormField';
import Can from '@/components/Permissions/Can';
import InventoryCheckIcon from '@/components/Purchasing/InventoryCheckIcon';
import PurchaseOrderItemsTable from '@/components/Purchasing/PurchaseOrderItemsTable';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderWithItems,
  PurchaseOrderItemPayload,
} from '@/types';
import { purchaseOrderItemPayloadSchema } from '@/types';
import { InventoryCheckResponse } from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import useDialogs from '@/utils/hooks/useDialogs';
import useSessionState from '@/utils/hooks/useSessionState';
import PurchaseOrderTotals from './PurchaseOrderTotals';

const CHANGE_MESSAGE = `
The associated order is invoiced already. 
Making this change will update GL entries and recalculate commissions. 
Are you sure you want to proceed?
`;

function getFields() {
  return [
    FieldFactory.curr('cost').with({ maxDecimals: 4 }),
    FieldFactory.number('qty'),
    FieldFactory.useDefault(
      'gl_account',
      FieldFactory.belongsTo('gl_account', 'accounts').withLabel('GL Account').withRequestParams({
        'filter[type][in]': 'cogs,expense',
      }),
    )
      .with({
        useDefaultLabel: 'This PO Item is Product (WIP)',
      })
      .withHelp(
        'If this is not product, please specify which GL account the bill for this item will go under. Common cases: Contract Decoration, LTL Freight, etc.',
      ),
    FieldFactory.textarea('note'),
  ];
}

export default function PurchaseOrderItems({
  purchaseOrder,
  onUpdated,
  onReload,
}: {
  purchaseOrder: PurchaseOrderWithItems;
  onUpdated: (data: Partial<PurchaseOrderWithItems>) => void;
  onReload?: () => void;
}) {
  const { items } = purchaseOrder;
  const { confirm, drawerPrompt } = useDialogs();
  const [isGrouped, setIsGrouped] = useSessionState('is_po_grouped', false);

  const {
    data: inventory,
    isLoading: isGettingInventory,
    mutate: getInventory,
  } = useMutation(() =>
    axios
      .post<InventoryCheckResponse>(`/api/purchase-orders/${purchaseOrder.id}/check-inventory`)
      .then(({ data }) => data),
  );

  const { mutate: updateCosts, isLoading: isUpdatingCosts } = useMutation(
    () => axios.post(`/api/purchase-orders/${purchaseOrder.id}/update-costs`),
    {
      onSuccess: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onReload ? onReload() : window.location.reload();
      },
    },
  );

  const onInventoryCheck = purchaseOrder.integration_name ? getInventory : undefined;

  const onUpdateCosts = purchaseOrder.invoice_number ? updateCosts : undefined;

  const updateTotalsFromResponse = ({ data }: AxiosResponse<PurchaseOrder>) => {
    onUpdated(pick(data, ['shipping', 'fee', 'subtotal', 'total']));
  };

  const reload = () => {
    axios
      .get<PurchaseOrder>(`/api/purchase-orders/${purchaseOrder.id}`)
      .then(updateTotalsFromResponse);
  };

  const updateItem = (id: number, payload: z.infer<typeof purchaseOrderItemPayloadSchema>) =>
    axios.put<PurchaseOrderItem>(`/api/purchase-order-items/${id}`, payload).then(({ data }) => {
      onUpdated({
        items: items.map((i) => {
          if (i.id === data.id) {
            return data;
          }
          return i;
        }),
      });
      reload();
    });

  const confirmAfterLocked = (item: PurchaseOrderItem, cb: () => void) => {
    if (item.is_locked) {
      confirm({ title: 'Order Invoiced', description: CHANGE_MESSAGE }).then(cb);
    } else {
      cb();
    }
  };

  const onCreate = () => {
    drawerPrompt({
      title: 'Create PO Item',
      fields: [
        FieldFactory.text('description'),
        FieldFactory.text('number'),
        FieldFactory.text('color'),
        FieldFactory.text('size'),
        ...getFields(),
      ],
      schema: purchaseOrderItemPayloadSchema,
      onSubmit: (values) =>
        axios.post<PurchaseOrderItem>(`/api/purchase-order-items`, {
          ...values,
          purchase_order_id: purchaseOrder.id,
        }),
    }).then(({ data }) => {
      onUpdated({ items: [...items, data] });
    });
  };

  const onEdit = (item: PurchaseOrderItem) => {
    const fields = getFields();
    if (item.is_inventory) {
      fields.push(
        FieldFactory.number('inventory_unit_conversion')
          .withInputProps({ step: 0.01 })
          .withHelp(
            'If we are purchasing in different units than what we store inventory in, please specify a conversion factor here.',
          ),
      );
    }
    confirmAfterLocked(item, () => {
      drawerPrompt({
        title: 'Update PO Item',
        description: (
          <div>
            <Typography variant="body2">{item.description}</Typography>
            <Typography variant="body2">Size: {item.size}</Typography>
            {item.variant && <Typography variant="body2">SKU: {item.variant.sku}</Typography>}
          </div>
        ),
        fields,
        schema: purchaseOrderItemPayloadSchema,
        initialValues: pick(item, [
          'description',
          'number',
          'color',
          'size',
          'cost',
          'qty',
          'gl_account',
          'note',
          'inventory_unit_conversion',
        ]),
        onSubmit: (v: PurchaseOrderItemPayload) => updateItem(item.id, v),
      });
    });
  };

  const onDelete = (item: PurchaseOrderItem) => {
    confirmAfterLocked(item, () => {
      confirm({
        title: 'Delete PO Line Item',
        description: 'Are you sure you want to delete this PO Line Item?',
        color: 'error',
      }).then(() => {
        axios.delete(`/api/purchase-order-items/${item.id}`).then(() => {
          onUpdated({ items: items.filter((i) => i.id !== item.id) });
          reload();
        });
      });
    });
  };

  return (
    <Box display="relative">
      <Box px={2} py={1} display="flex" alignItems="center">
        <FormControlLabel
          label="Grouped"
          control={<Switch checked={isGrouped} onChange={(e) => setIsGrouped(e.target.checked)} />}
        />
        <Box ml="auto">
          <Tooltip title="Export to Excel">
            <IconButton
              component="a"
              href={getApiUrl(
                `/api/purchase-order-items?purchase_order_id=${purchaseOrder.id}&format=xlsx`,
              )}
              size="large"
            >
              <Download />
            </IconButton>
          </Tooltip>
          {onInventoryCheck && (
            <Tooltip title="Check Inventory">
              <IconButton onClick={() => onInventoryCheck()} size="large">
                <SyncAlt />
              </IconButton>
            </Tooltip>
          )}
          {onUpdateCosts && (
            <Tooltip title="Update Costs">
              <IconButton onClick={() => onUpdateCosts()} size="large">
                <Sync />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <PurchaseOrderItemsTable
        items={purchaseOrder.items}
        isGrouped={isGrouped}
        renderActions={(i) => (
          <TableCell style={{ whiteSpace: 'nowrap' }}>
            <Can permission="write:purchase_orders">
              <IconButton onClick={() => onEdit(i)} size="small">
                <Edit fontSize="small" />
              </IconButton>

              {!i.is_locked && (
                <IconButton onClick={() => onDelete(i)} size="small">
                  <Delete fontSize="small" />
                </IconButton>
              )}

              <InventoryCheckIcon inventory={inventory} sku={i.variant?.sku} />
            </Can>
          </TableCell>
        )}
      />
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 7, lg: 9 }}>
            <FormField
              field={FieldFactory.textarea('memo').withLabel('Memo').withHelp('Will appear on PO')}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 5, lg: 3 }}>
            <PurchaseOrderTotals purchaseOrder={purchaseOrder} onUpdated={onUpdated} />
          </Grid>
        </Grid>
      </Box>
      <LoadingBackdrop loading={isUpdatingCosts || isGettingInventory} />
      {purchaseOrder.type === 'general' && <CreateButton onClick={onCreate} />}
    </Box>
  );
}
