import { useCallback, useMemo } from 'react';
import { QueryKey, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import trimStart from 'lodash/trimStart';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '@/contexts/AppContext';
import {
  CamelValues,
  ResourceMap,
  ResourceName,
  ResourceValueMap,
  ResourceValues,
} from '@/resources/types';
import useGetResource, { useResource } from '@/utils/hooks/useGetResource';

type Mode = 'index' | 'create' | 'edit';

export function camelize(obj: FieldValues) {
  return mapKeys(obj, (_, k) => camelCase(k));
}

export function useRecordId() {
  // const {id} = useParams<{ id?: string }>();
  return useLocation().pathname.split('/')[2];
}

export function useMode(): [Mode, string | undefined] {
  const id = useRecordId();

  if (!id) {
    return ['index', undefined];
  }
  if (id == 'create') {
    return ['create', undefined];
  }
  return ['edit', id];
}

export function makeResourceQueryKey(
  resourceKey: ResourceName,
  pk: string | number,
  add?: string,
): QueryKey {
  const base = ['genericRecord', resourceKey, String(pk)];
  if (add) {
    base.push(add);
  }
  return base;
}

export function useMaybeCurrentResource(): ResourceName | undefined {
  const { routes } = useAppContext();
  const { pathname } = useLocation();
  const getResource = useGetResource();

  return useMemo(() => {
    return routes.find((r) => trimStart(r.href, '/') === pathname.split('/')[1])?.resource;
  }, [routes, pathname, getResource]);
}

export function useCurrentResource(): ResourceName {
  const resource = useMaybeCurrentResource();
  if (!resource) {
    throw new Error('No current resource');
  }
  return resource;
}

export function useResourceQuery<K extends ResourceName, V extends ResourceValueMap[K]>(
  key: K,
  refetchOnMount = true,
): UseQueryResult<V> {
  const resource = useResource(key);
  const pk = useRecordId();
  const queryKey = makeResourceQueryKey(key, pk);

  return useQuery(queryKey || [], () => resource.getShowRequest(pk).then(({ data }) => data), {
    enabled: Boolean(pk) && pk !== 'create',
    refetchOnMount,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}

export function useRecord<K extends ResourceName>(
  key: K,
  convert: true,
): CamelValues<ResourceValueMap[K]>;

export function useRecord<K extends ResourceName>(key: K): ResourceValueMap[K];

export function useRecord<R extends ResourceValues = ResourceValues>(): R;

export function useRecord<K extends ResourceName = ResourceName>(
  key?: K,
  convert?: true,
): CamelValues<ResourceValueMap[K]> | ResourceValueMap[K] | ResourceValues {
  const currentResource = useMaybeCurrentResource();
  const { data } = useResourceQuery(key || currentResource || 'orders');
  if (!data) {
    throw new Error('There is no record loaded');
  }
  if (!convert) {
    return data as unknown as ResourceValueMap[K];
  }
  return camelize(data) as CamelValues<ResourceValueMap[K]>;
}

export function useMaybeRecord(): ResourceValues | undefined {
  const currentResource = useMaybeCurrentResource();
  const { data } = useResourceQuery(currentResource || 'orders');
  return data;
}

export function useUpdateRecord<K extends ResourceName>(key?: K) {
  const currentResource = useMaybeCurrentResource();
  const pk = useRecordId();
  const queryKey = makeResourceQueryKey(key || currentResource || 'orders', pk);

  const queryClient = useQueryClient();
  const form = useFormContext();

  return useCallback(
    (updated: Partial<ResourceValueMap[K]>) => {
      if (queryKey) {
        form?.reset(updated);
        const oldRes = queryClient.getQueryData(queryKey) as ResourceMap[K] | undefined;
        if (oldRes) {
          queryClient.setQueryData(queryKey, { ...oldRes, ...updated });
        }
      }
    },
    [queryClient, queryKey],
  );
}

export function useOnReloadRecord<K extends ResourceName>(key?: K) {
  const queryClient = useQueryClient();
  const currentResource = useMaybeCurrentResource();
  const pk = useRecordId();
  const queryKey = makeResourceQueryKey(key || currentResource || 'orders', pk);

  return useCallback(() => {
    if (queryKey) {
      queryClient.invalidateQueries(queryKey);
    }
  }, [queryClient, queryKey]);
}
