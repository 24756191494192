import { Card, CardHeader, Stack } from '@mui/material';
import moment from 'moment';
import { useGetKitsForOrder } from '@/api/packout';
import KitLabel from '@/components/Kitting/KitLabel';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import StatusChip from '@/components/Shared/StatusChip';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import { PICK_STATUS_COLORS } from '@/constants';
import { Kit } from '@/types';
import { useRecord } from '@/utils/genericResource';

function toTimestamp(timestamp: string | null): string {
  return timestamp ? moment(timestamp).format('lll') : '-';
}

export default function PackoutCompleteStatus() {
  const { order } = useRecord('orderPackouts', true);

  const { data: kits = [], refetch, isFetching } = useGetKitsForOrder(order.id);

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title="Kits in Order" titleTypographyProps={{ variant: 'h6' }} />

        <PaginatedTable
          searchable={['name', 'number']}
          rows={kits}
          initialSortBy={{ id: 'bin_string', desc: false }}
          onReload={refetch}
          isFetching={isFetching}
          columnVisibility={{
            group: false,
            email: false,
          }}
          columns={[
            {
              header: 'Bin',
              accessorKey: 'bin_string',
              enableHiding: false,
              cell: ({ row }) => <KitLabel kit={row.original} />,
            },
            { header: 'Name', accessorKey: 'name' },
            { header: 'Number', accessorKey: 'number' },
            { header: 'Group', accessorKey: 'group' },
            { header: 'Email', accessorKey: 'email' },
            {
              header: 'Status',
              accessorKey: 'pick_status',
              cell: ({ getValue }) => (
                <StatusChip
                  status={getValue<Kit['pick_status']>()}
                  colors={PICK_STATUS_COLORS}
                  size="small"
                />
              ),
            },
            {
              accessorKey: 'pick_started_at',
              header: 'Binned At',
              cell: ({ getValue }) => toTimestamp(getValue<string | null>()),
            },
            {
              accessorKey: 'pick_completed_at',
              header: 'Packed At',
              cell: ({ getValue }) => toTimestamp(getValue<string | null>()),
            },
          ]}
          filename={`kits-${order.increment_id.toLowerCase()}`}
        />
      </Card>

      <ShipmentsCard shippableId={order.id} shippableType="order" />
    </Stack>
  );
}
