import { ChangeEvent, CSSProperties, useMemo, useState } from 'react';
import { Check, PlaylistAddCheck, Refresh, Search, Undo } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { usePerformKitAction } from '@/api/packout';
import AddressTitle from '@/components/Addresses/AddressTitle';
import BatchShippingDrawer from '@/components/BatchShipping/BatchShippingDrawer';
import KitLabel from '@/components/Kitting/KitLabel';
import PrintMenu from '@/components/Print/PrintMenu';
import DebouncedTextField from '@/components/Shared/DebouncedTextField';
import StatusChip from '@/components/Shared/StatusChip';
import ShipActions from '@/components/Shipping/ShipActions';
import { PICK_STATUS_COLORS } from '@/constants';
import { Address, AddressWithKits, Kit, ShipmentItemPayload } from '@/types';
import useDialogs from '@/utils/hooks/useDialogs';
import numString from '@/utils/numString';
import searchCollection from '@/utils/searchCollection';

const toShip = (i: Kit) => i.pick_status === 'pending_shipment';

export default function OrderShippingKit({
  orderId,
  customerId,
  addresses: allAddresses,
  refetch,
}: {
  orderId: number;
  customerId: number;
  addresses: AddressWithKits[];
  refetch?: () => void;
}) {
  const [isBatchShipping, setIsBatchShipping] = useState(false);
  const [selected, setSelected] = useState<ShipmentItemPayload[]>([]);
  const [tab, setTab] = useState<'all' | 'toship' | 'shipped'>('all');
  const [selectingForAddress, setSelectingForAddress] = useState<number>();
  const [query, setQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const { confirm } = useDialogs();

  const performKitAction = usePerformKitAction(orderId);

  const handleKitAction = (kit: Kit, action: 'start' | 'complete' | 'reset') => {
    confirm({
      title: `${startCase(action)} Kit: ${kit.number || kit.name}`,
      description: 'Are you sure you want to take this action?',
    }).then(() => {
      performKitAction.mutate({ id: kit.id, action });
    });
  };

  const addresses = useMemo(() => {
    const results = allAddresses
      .map((address) => {
        let kits = orderBy(
          address.kits.map((kit) => ({
            ...kit,
            address_name: address.name,
          })),
          'bin_number',
        );
        if (tab === 'toship') {
          kits = kits.filter(toShip);
        }
        if (tab === 'shipped') {
          kits = kits.filter((k) => k.pick_status === 'complete');
        }
        if (query) {
          kits = searchCollection(kits, query, ['number', 'name', 'address_name', 'bin_string']);
        }
        return { ...address, kits, main_bin: kits[0]?.bin_number };
      })
      .filter((a) => a.kits.length > 0);

    return orderBy(results, 'main_bin');
  }, [allAddresses, tab, query]);

  const handleCheck = (kit: Kit, address: Address) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (address.id === selectingForAddress) {
        setSelected([
          ...selected,
          {
            kit_id: kit.id,
            qty_shipped: 1,
          },
        ]);
      } else {
        setSelectingForAddress(address.id);
        setSelected([
          {
            kit_id: kit.id,
            qty_shipped: 1,
          },
        ]);
      }
    } else {
      setSelected(selected.filter((s) => s.kit_id !== kit.id));
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>, address: AddressWithKits) => {
    if (selectingForAddress === address.id && selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(
        address.kits.filter(toShip).map((k) => ({
          kit_id: k.id,
          qty_shipped: 1,
        })),
      );
    }
    setSelectingForAddress(address.id);
  };

  const onShipSuccess = () => {
    setSelected([]);
    setSelectingForAddress(undefined);
  };

  const address = addresses.find((a) => a.id === selectingForAddress);

  const actions = (
    <div>
      {address && selected.length > 0 ? (
        <ShipActions
          payload={{
            shippable_type: 'order',
            shippable_id: orderId,
            address_id: address.id,
            items: selected,
          }}
          customerId={customerId}
          title={`Shipping ${numString(selected.length, 'Kits')}`}
          qty={selected.length}
          onSuccess={onShipSuccess}
          addressMethod={address.method}
        />
      ) : (
        <>
          {!searching ? (
            <IconButton onClick={() => setSearching(true)} size="large">
              <Search />
            </IconButton>
          ) : (
            <DebouncedTextField
              initialValue={query}
              onChange={setQuery}
              onBlur={() => setSearching(false)}
              autoFocus
              label="Search"
              size="small"
            />
          )}

          {refetch && (
            <IconButton onClick={refetch} size="large">
              <Refresh />
            </IconButton>
          )}
          <Tooltip title="Batch Ship">
            <IconButton onClick={() => setIsBatchShipping(true)} size="large">
              <PlaylistAddCheck />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );

  const renderAddress = ({ index, style }: { index: number; style: CSSProperties }) => {
    const address = addresses[index];
    const hasGroup = address.kits.some((k) => !!k.group);
    return (
      <div style={style}>
        <CardContent>
          <AddressTitle address={address} />
        </CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  {address.kits.filter(toShip).length > 0 && (
                    <Checkbox
                      size="small"
                      onChange={(e) => handleCheckAll(e, address)}
                      checked={
                        selectingForAddress === address.id &&
                        selected.length === address.kits.filter(toShip).length
                      }
                      indeterminate={
                        selectingForAddress === address.id &&
                        selected.length > 0 &&
                        selected.length < address.kits.filter(toShip).length
                      }
                    />
                  )}
                </TableCell>
                <TableCell>Bin</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Name</TableCell>
                {hasGroup && <TableCell>Group</TableCell>}
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {address.kits.map((kit) => (
                <TableRow key={kit.id}>
                  <TableCell padding="checkbox">
                    {toShip(kit) && (
                      <Checkbox
                        size="small"
                        onChange={handleCheck(kit, address)}
                        checked={selected.some((s) => s.kit_id === kit.id)}
                      />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 75 }}>
                    <KitLabel kit={kit} />
                  </TableCell>
                  <TableCell style={{ width: 150 }}>{kit.number}</TableCell>
                  <TableCell style={{ width: 200 }}>{kit.name}</TableCell>
                  {hasGroup && <TableCell style={{ width: 200 }}>{kit.group}</TableCell>}
                  <TableCell style={{ width: 100 }}>
                    <StatusChip status={kit.pick_status} colors={PICK_STATUS_COLORS} size="small" />
                  </TableCell>
                  <TableCell style={{ width: 100 }}>
                    <PrintMenu model="kit" id={kit.id} size="small" />

                    {kit.pick_status === 'issued' ? (
                      <Tooltip title="Start Picking">
                        <IconButton onClick={() => handleKitAction(kit, 'start')} size="small">
                          <Check />
                        </IconButton>
                      </Tooltip>
                    ) : kit.pick_status === 'picking' ? (
                      <Tooltip title="Start Packing">
                        <IconButton onClick={() => handleKitAction(kit, 'complete')} size="small">
                          <Check />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Reset Pick">
                        <IconButton onClick={() => handleKitAction(kit, 'reset')} size="small">
                          <Undo />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <Card>
      <CardHeader title="Kits to Ship" action={actions} />

      <Tabs onChange={(e, t) => setTab(t)} value={tab}>
        <Tab value="all" label="All" />
        <Tab value="toship" label="To Ship" />
        <Tab value="shipped" label="Shipped" />
      </Tabs>

      <div style={{ height: 700 }}>
        {addresses.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                height={Number(height)}
                width={Number(width)}
                itemSize={(index) => addresses[index].kits.length * 40 + 120}
                itemCount={addresses.length}
                itemKey={(index) => addresses[index].id}
              >
                {renderAddress}
              </VariableSizeList>
            )}
          </AutoSizer>
        ) : (
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              No kits found
            </Typography>
          </CardContent>
        )}
      </div>

      <BatchShippingDrawer
        open={isBatchShipping}
        onClose={() => setIsBatchShipping(false)}
        orderId={orderId}
        customerId={customerId}
      />
    </Card>
  );
}
