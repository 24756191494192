import { Fragment, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import orderBy from 'lodash/orderBy';
import { Route, Routes } from 'react-router-dom';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import { AppProvider } from '@/contexts/AppContext';
import { NavItem, Page } from '@/resources/types';
import AlertDialogContainer from '../Dialogs/AlertDialogContainer';
import ConfirmDialogContainer from '../Dialogs/ConfirmDialogContainer';
import PromptDialogContainer from '../Dialogs/PromptDialogContainer';
import PromptDrawerContainer from '../Dialogs/PromptDrawerContainer';
import AppBar from './AppBar';
import AppNavigation from './AppNavigation';
import ToastContainer from './ToastContainer';

const AppFrame = styled('div')({
  zIndex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '100vh',
  flexGrow: 1,
  fontFamily: 'Roboto',
});

const AppContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.drawerWidth,
  },
}));

function flatten(pages: NavItem[]): Page[] {
  return pages.flatMap((page) => {
    if ('component' in page) {
      return [page];
    }
    if ('children' in page) {
      return flatten(page.children);
    }
    return [];
  });
}

interface AppProps {
  title: string;
  pages: NavItem[];
  routes: Page[];
  logo: string | ReactNode;
  drawerChildren?: ReactNode;
  userActions?: ReactNode;
  children?: ReactNode;
}

export default function App(props: AppProps) {
  const { title, pages, routes = [], logo, drawerChildren, userActions, children } = props;

  // Flatten
  const withRoutes = orderBy(flatten(pages).concat(routes), (p) => p.href.length, 'desc');

  return (
    <AppProvider title={title} routes={withRoutes}>
      <AppFrame id="app-frame">
        <AppBar />
        <AppNavigation pages={pages} logo={logo} userActions={userActions}>
          {drawerChildren}
        </AppNavigation>

        <AppContent>
          <Box p={2}>
            <Routes>
              {withRoutes.map((page) => {
                const Page = page.component;
                const node = <Page key={page.href} page={page} />;
                return (
                  <Fragment key={page.href}>
                    <Route path={page.href} element={node} />
                    <Route path={`${page.href}/:id`} element={node} />
                  </Fragment>
                );
              })}
            </Routes>
          </Box>

          {children}

          <LoadingBackdrop />
          <ToastContainer />
          <AlertDialogContainer />
          <ConfirmDialogContainer />
          <PromptDialogContainer />
          <PromptDrawerContainer />
        </AppContent>
      </AppFrame>
    </AppProvider>
  );
}
